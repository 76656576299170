
import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { getAuth, signOut } from 'firebase/auth';
import { Bars3Icon} from '@heroicons/react/24/outline'
import { Dialog } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'

const navigation = [
  { name: 'Bookings', link: 'Bookings' },
  { name: 'Appointments', link: 'Appointments' },
  { name: 'About Us', link: 'About Us' },
  { name: 'Contact Us', link: 'ContactUs' },
]

const Navbar = () => {
  
  const [isToken,setIsToken] = React.useState(false)
  const [authState,setAuthState] = React.useState(false)
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false)
  const activeLink = 'bg-red-400 flex items-center pt-3 pb-2.5 rounded-lg  text-white  text-md w-full p-1';
  const normalLink = 'flex items-center  pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 w-full p-1';

  const navigate = useNavigate()

  React.useEffect(() =>{
    const localStorage = window.localStorage
    const check = localStorage.getItem('userIn')
    check ? setAuthState(true) : setAuthState(false)
  },[])

  const logout = () =>{
    try{
      const auth = getAuth()
      signOut(auth)
      .then(() =>{
        const localstorage = window.localStorage
        localstorage.removeItem('userIn')
        localstorage.removeItem('userId')
        setAuthState(false)
        navigate('/')
      })
    }
    catch(err){
      console.log(err)
    }
  }



  return (
    <header className="absolute inset-x-0 top-0 z-50">

<nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
          <div className="flex lg:flex-1">
            <Link to="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              {/* <img
                className="h-8 w-auto"
                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                alt=""
              /> */}
              <div className='bg-indigo-600 p-2 rounded-lg'>
                <h1 className='font-extrabold text-lg text-white'>PD</h1>
              </div>
            </Link>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              // <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-gray-900">
              //   {item.name}
              // </a>
              <button 
              className="text-sm font-semibold leading-6 text-gray-900" 
              onClick={() => navigate(`/${item.link}`)}>
                {item.name}
              </button>
            ))}
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            {
              authState ?
              <button className="text-sm font-semibold leading-6 text-gray-900" onClick={logout}>
                logout<span aria-hidden="true">&rarr;</span>
              </button>
              :
              <button onClick={() =>navigate('/Userlogin')} className="text-sm font-semibold leading-6 text-gray-900">
              Log in <span aria-hidden="true">&rarr;</span>
            </button>
            }
          </div>
        </nav>
        
        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <div className='bg-indigo-600 p-2 rounded-lg'>
                  <h1 className='font-extrabold text-lg text-white'>PD</h1>
                </div>
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <button
                      key={item.name}
                      onClick={() => navigate(`/${item.link}`)}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </button>
                  ))}
                </div>
                <div className="py-6">
                {
                  authState ?
                  <button className="text-sm font-semibold leading-6 text-gray-900" onClick={logout}>
                    logout<span aria-hidden="true">&rarr;</span>
                  </button>
                  :
                  <button onClick={() =>navigate('/Userlogin')} className="text-sm font-semibold leading-6 text-gray-900">
                  Log in <span aria-hidden="true">&rarr;</span>
                </button>
                }
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
   
  )
}

export default Navbar