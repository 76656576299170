/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React,{useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import { collection, doc, getDoc, getDocs, orderBy, query,where } from 'firebase/firestore'
import { database } from '../firebase/firebaseConfig'
import Footer from '../components/Footer'


export default function BookingInfo() {
    const localstorage = window.localStorage
    const userid = localstorage.getItem('userId')

    const [orders,setOrders] = useState([])
    

  useEffect(() =>{
    const getData = async() =>{
        try{
                const collRef = collection(database,'BloodTestBookings')
                const q = query(collRef,where('userId','==',userid),orderBy('created_at','desc'))
                console.log(q)
                await getDocs(q)
                .then((snapshot) =>{
                    let temp = []
                    snapshot.docs.forEach((doc) =>{
                        const data = doc.data()
                        const timestampInSeconds = data.created_at.seconds;
                        const date = new Date(timestampInSeconds * 1000); // Convert seconds to milliseconds
                        const formattedDate = date.toDateString();
                        data.created_at = formattedDate
                        temp.push({...data})
                    })
                    setOrders(temp)
                })
        }
        catch(err){
            console.log(err)
        }
    }
    getData()
  },[])

  return (
    <div>
      {/* Mobile menu */}
      <Navbar/>
      <svg
            className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
              >
                <path d="M100 200V.5M.5 .5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
              <path
                d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect width="100%" height="100%" strokeWidth={0} fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" />
          </svg>

      <main className="mt-10 mx-auto max-w-3xl px-4 py-16 sm:px-6 sm:pb-32 sm:pt-24 lg:px-8">
        <div className="max-w-xl">
          <h1 className="text-3xl font-bold tracking-tight text-gray-900">Your Medical Test Bookings</h1>
          <p className="mt-2 text-sm text-gray-500">
            History of medical test booked
          </p>
        </div>

        {
          userid !== null ?
          <div className="mt-12 space-y-16 sm:mt-16">
            {  
            orders.map((order) =>(
                <section key={order.created_at} className="-mb-6 mt-6 flow-root  border-t border-gray-200">
                    <h1 className='font-bold text-xl'>{order.created_at}</h1>
                    { 
                        order.test.map((item) =>{
                          return(
                            <div className="flex space-x-4 py-2 sm:min-w-0 sm:flex-1 sm:space-x-6 lg:space-x-8">
                                <h1 className="text-sm font-medium text-gray-900">Test: {item.name}</h1>
                                <h1 className="text-sm font-medium text-gray-900">Price: {item.price}</h1>
                            </div>
                          )
                        })
                    }
                </section>
            ))
            }
        </div>
        :
        <h1 className='mt-20'>
          No bookings done or You may not be logged in
        </h1>
        }
      </main>


      <Footer/>
    </div>
  )
}
