import React,{useEffect, useState} from 'react'
import Button from "@mui/material/Button";
import NavBar from '../components/Navbar'

import { Link } from 'react-router-dom';
import Footer from '../components/Footer';

import { getDoc,doc,getDocs,collection, addDoc} from 'firebase/firestore'
import {database} from '../firebase/firebaseConfig'
import { Avatar } from '@mui/material';


const SuccessMessage = () =>(
    <div className='w-full h-screen flex flex-col items-center mt-20 gap-20 px-10'>
            
            <h1 className='text-7xl font-extrabold tracking-tighter text-slate-800'>Prashanti Medical</h1>
            <div className='text-center'>
                <h1 className='text-2xl font-bold tracking-tighter'>Your Appointment has been filled</h1>
                <h1 className='text-2xl'>We will send an Email upon confirmation</h1>
                <h1 className='text-xl font-extrabold text-emerald-700'>Thank You!</h1>
            </div>

            <div className='flex gap-4'>

                <Link to='/'>
                    <Button variant="contained" type="submit">
                    Go to Home
                    </Button>
                </Link>
            </div>
    </div>
)


const DoctorAppointment = () => {

  const [userData,setUserData] = useState({})
  const [success,setSuccess] = useState(false)
  const [doctorList,setDoctorList] = useState([])

  const localstorage = window.localStorage
  const userid = localstorage.getItem('userId')

  useEffect(() =>{
    const collRef = collection(database,'DoctorData')
    const ref = doc(database,'users',userid)
    
    
    const unsubscribe = async() =>{
        await getDocs(collRef)
        .then(snapshot =>{
            let temp=[]
            snapshot.docs.forEach(doc =>{
                temp.push(doc.data())
            })
            setDoctorList(temp)
        })

        await getDoc(ref)
        .then((response) =>{
            const data = response.data()
            setUserData(data)
        })
    }
    unsubscribe()

    return()=> unsubscribe()
  },[])

  const submitForm = async(name,role) =>{
        const bookRef = collection(database,'DoctorBookings')
        const currentDate = new Date();
        const details = {
            patientName:userData.username,
            patientNumber:userData.phoneNumber,
            patientEmail:userData.email,
            patientAddress:userData.streetAddress,
            doctor:name,
            role:role,
            approved:false,
            created_at: currentDate,
            userId:userid
        }
        await addDoc(bookRef,details)
        .then(() =>{
            console.log('document added')
            setSuccess(true)
        })
  }

  return (
    <div className='mt-28  overflow-x-hidden min-h-screen'>
        <NavBar/>

        <svg
            className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
              >
                <path d="M100 200V.5M.5 .5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
              <path
                d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect width="100%" height="100%" strokeWidth={0} fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" />
          </svg>
        
     
    <div className="border-b border-gray-200 pb-5 p-10">
        <h3 className="text-3xl tet-base font-semibold leading-6 text-gray-900">Book Appointments</h3>
        <p className="mt-2 max-w-4xl text-sm text-gray-500">
        Making an Appointment has never been easier
        </p>
    </div>

    {
        success ?
        <div>
            <SuccessMessage/>
        </div>
        :
        <ul role="list" className="px-10 m-4 grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
      {doctorList && doctorList.map((person) => (
        <li
          key={person.name}
          className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow-2xl"
        >
          <div className="flex flex-1 flex-col p-8">
            {/* <img className="mx-auto h-32 w-32 flex-shrink-0 rounded-full" src={person.imageUrl} alt="" /> */}
            <Avatar className="mx-auto flex-shrink-0"  sx={{ width: 52, height: 52 }}>{person.name[3]}</Avatar>
            <h3 className="mt-6 text-sm font-medium text-gray-900">{person.name}</h3>
            <dl className="mt-1 flex flex-grow flex-col justify-between">
              <dt className="sr-only">Title</dt>
              <dd className="text-sm text-gray-500">{person.title}</dd>
              <dt className="sr-only">Role</dt>
              <dd className="mt-3">
                <span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                  {person.role}
                </span>
              </dd>
            </dl>
          </div>
          <div>
            <div className="-mt-px flex divide-x divide-gray-200">
              <div className="flex w-0 flex-1">
                {
                    person.availability === 'On Duty' ?
                    <section className='w-full flex justify-center p-4'>
                        <button onClick={() =>submitForm(person.name,person.role)} className="bg-green-400/50  rounded-full p-3 text-white">Make an Appointment</button>
                    </section>
                    :
                    <section className='w-full flex justify-center p-4'>
                        <span className="bg-gray-400/50  rounded-full p-3 text-white">Unavailable</span>
                    </section>
                }
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
    }

    <Footer/>

    </div>
  )
}

export default DoctorAppointment