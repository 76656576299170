import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'


const timeline = [
  {
    name: 'Founded company',
    description:
      ' Our journey began in 2007 with a modest start – a small medical shop nestled in the heart of Golitar, Singtam. From these humble beginnings, we have woven a tapestry of healing and tranquility that spans over a decade.',
    date: 'Aug 2007',
    dateTime: '2007-08',
  },
  {
    name: 'diagnostic center and a clinic',
    description:
      `In the year 2020, a new chapter was etched into our journey. We extended our horizons to encompass a state-of-the-art diagnostic center and a clinic for esteemed doctors – a testament to our unwavering devotion to offering holistic care.`,

    date: 'Dec 2020',
    dateTime: '2020-12',
  },
  {
    name: 'Released Website',
    description:
      "Fast forward to 2023, and we're delighted to introduce the digital extension of Prashanti through this website. A simple click allows you to request the comfort of home blood test collection, while the same digital gateway offers seamless scheduling of appointments with our esteemed doctors.",
    date: 'Feb 2023',
    dateTime: '2023-02',
  },
  // {
  //   name: 'Global launch of product',
  //   description:
  //     'Ut ipsa sint distinctio quod itaque nam qui. Possimus aut unde id architecto voluptatem hic aut pariatur velit.',
  //   date: 'Dec 2022',
  //   dateTime: '2022-12',
  // },
]





const Aboutus = () => {
  return (
    <div className='bg-light-gray'>

      {/* Welcome to Prashanti – Your Sanctuary of Highest Peace in Healthcare

At Prashanti, we believe that true wellness encompasses not only the absence of illness but also the presence of the highest peace within. Our journey began in 2007 with a modest start – a small medical shop nestled in the heart of Golitar, Singtam. From these humble beginnings, we have woven a tapestry of healing and tranquility that spans over a decade.

The very essence of our name, "Prashanti," encapsulates our profound mission – to provide the pinnacle of serenity and tranquility through our comprehensive medical services. We have painstakingly nurtured an oasis of health where individuals can seek solace, healing, and the pursuit of wellbeing, all unified under one roof.

Our evolution from a simple pharmacy to the esteemed establishment we stand as today has been marked by relentless dedication, tireless efforts, and the unwavering support of countless individuals. It's through the collective commitment of our team, the trust of our patrons, and the encouragement of our community that we've grown, step by step.

In the year 2020, a new chapter was etched into our journey. We extended our horizons to encompass a state-of-the-art diagnostic center and a clinic for esteemed doctors – a testament to our unwavering devotion to offering holistic care. Within the embrace of our establishment, we merged a pharmacy shop and a sanctuary for generic medicines. This fusion gave birth to what we affectionately term "The Temple of Healing" – a refuge where healing transcends mere process, becoming a transformative experience.

With heartfelt gratitude, we recognize the indispensable role that our supporters, well-wishers, and dedicated staff members have played in our voyage. Their contributions have formed the very foundation upon which we've built our dreams into reality.

Fast forward to 2023, and we're delighted to introduce the digital extension of Prashanti through this website. A simple click allows you to request the comfort of home blood test collection, while the same digital gateway offers seamless scheduling of appointments with our esteemed doctors. Understanding the value of time, we ensure that your health is never compromised by inconvenience.

Our website also unveils the varied timings of our doctors, ensuring unhindered access to care, with each visit optimized to align with your schedule. This platform exemplifies our commitment to harmonizing modern technology with traditional healing wisdom.

Prashanti isn't merely a name; it's a symbol of the highest peace that every individual deserves. We invite you to explore our digital haven, engage with our services, and join us in our endeavor to nurture well-being and tranquility. As we continue on this voyage, we hold dear the values that shaped us and the people who sustained us.

Thank you for being an integral part of the Prashanti story.

With serenity and healing,
The Prashanti Family

*Location:*
You can find us near the post office, adjacent to the old bridge, Singtam, East Sikkim. This has been our home since 2020, where we've been dedicated to bringing you the highest level of peace and care in healthcare. */}

      <div>
        <Navbar/>
      

<main className="isolate">
        {/* Hero section */}
        <div className="relative isolate -z-10 overflow-hidden bg-gradient-to-b from-indigo-100/20 pt-14">
          <div
            className="absolute inset-y-0 right-1/2 -z-10 -mr-96 w-[200%] origin-top-right skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:-mr-80 lg:-mr-96"
            aria-hidden="true"
          />
          <div className="mx-auto max-w-7xl px-6 py-32 sm:py-40 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
              <h1 className="max-w-2xl text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl lg:col-span-2 xl:col-auto">
              Welcome to Prashanti – Your Sanctuary of Highest Peace in Healthcare
              </h1>
              <div className="mt-6 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
                <p className="text-lg leading-8 text-gray-600">
                At Prashanti, we believe that true wellness encompasses not only the absence of illness but also the presence of the highest peace within.
                </p>
              </div>
              <img
                src={require('../images/doctor.jpg')} 
                alt=""
                className="mt-10 aspect-[6/5] w-full max-w-lg rounded-2xl object-cover sm:mt-16 lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 xl:mt-36"
              />
            </div>
          </div>
          <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
        </div>

        {/* Timeline section */}
        <div className="mx-auto -mt-8 max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-8 overflow-hidden lg:mx-0 lg:max-w-none lg:grid-cols-4">
            {timeline.map((item) => (
              <div key={item.name}>
                <time
                  dateTime={item.dateTime}
                  className="flex items-center text-sm font-semibold leading-6 text-indigo-600"
                >
                  <svg viewBox="0 0 4 4" className="mr-4 h-1 w-1 flex-none" aria-hidden="true">
                    <circle cx={2} cy={2} r={2} fill="currentColor" />
                  </svg>
                  {item.date}
                  <div
                    className="absolute -ml-2 h-px w-screen -translate-x-full bg-gray-900/10 sm:-ml-4 lg:static lg:-mr-6 lg:ml-8 lg:w-auto lg:flex-auto lg:translate-x-0"
                    aria-hidden="true"
                  />
                </time>
                <p className="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">{item.name}</p>
                <p className="mt-1 text-base leading-7 text-gray-600">{item.description}</p>
              </div>
            ))}
          </div>
        </div>

        
        {/* Content section */}
        <div className="mt-32 overflow-hidden sm:mt-40">
          <div className="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
              <div className="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Our Team</h2>
                <p className="mt-6 text-xl leading-8 text-gray-600">
                At Prashanti Diagnostics, we take pride in our team of highly skilled and experienced doctors, specialists, and support staff who are dedicated to delivering the best healthcare services to our patients. 
                </p>
                <p className="mt-6 text-base leading-7 text-gray-600">
                Our medical professionals are not only well-qualified but also passionate about their work, ensuring that you receive the highest standard of care.
                </p>
              </div>
              <div className="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents">
                <div className="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end">
                  <img
                    src="https://images.unsplash.com/photo-1670272502246-768d249768ca?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1152&q=80"
                    alt=""
                    className="aspect-[7/5] w-[37rem] max-w-none rounded-2xl bg-gray-50 object-cover"
                  />
                </div>
                <div className="contents lg:col-span-2 lg:col-end-2 lg:ml-auto lg:flex lg:w-[37rem] lg:items-start lg:justify-end lg:gap-x-8">
                  <div className="order-first flex w-64 flex-none justify-end self-end lg:w-auto">
                    <img
                      src="https://images.unsplash.com/photo-1605656816944-971cd5c1407f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=768&h=604&q=80"
                      alt=""
                      className="aspect-[4/3] w-[24rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover"
                    />
                  </div>
                  <div className="flex w-96 flex-auto justify-end lg:w-auto lg:flex-none">
                    <img
                      src="https://images.unsplash.com/photo-1568992687947-868a62a9f521?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1152&h=842&q=80"
                      alt=""
                      className="aspect-[7/5] w-[37rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover"
                    />
                  </div>
                  <div className="hidden sm:block sm:w-0 sm:flex-auto lg:w-auto lg:flex-none">
                    <img
                      src="https://images.unsplash.com/photo-1612872087720-bb876e2e67d1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=768&h=604&q=80"
                      alt=""
                      className="aspect-[4/3] w-[24rem] max-w-none rounded-2xl bg-gray-50 object-cover"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Stats */}
              </main>

      {/* Footer */}
      <Footer/>
    </div>
    </div>
  )
}

{/* </div>

      <div className='px-4 md:px-8 md:mt-10'>
        <h1 className='font-bold text-3xl md:text-5xl font-EB text-slate-900
                        md:text-center'>
                          About Us
        </h1>

        <div className='flex flex-col gap-5 md:gap-10 mt-2 md:mt-5 md:items-center'>
          <h1 className='font-Playfair md:text-3xl'>Welcome to Prashanti – Your Sanctuary of Highest Peace in Healthcare</h1>
         
            <div className='flex md:justify-center'>
              <img src={require('../images/doctor.jpg')} className='rounded-2xl md:w-[60%]'/>
            </div>

            <p className='text-sm font-Playfair
                          md:text-lg'>
            At Prashanti, we believe that true wellness encompasses not only the absence of illness but also the presence of the highest peace within. Our journey began in 2007 with a modest start – a small medical shop nestled in the heart of Golitar, Singtam. From these humble beginnings, we have woven a tapestry of healing and tranquility that spans over a decade.
            </p>

            <div className='flex  gap-4 items-end'>
              <div className='w-1/2 flex flex-col'>
                <h1 className='text-3xl font-EB font-bold text-slate-700
                                md:text-5xl'>
                                  Our Mission
                </h1>
                <img src={require('../images/test2.jpg')} className='rounded-xl h-48 md:h-96'/>
              </div> */}
              {/* <img src={require('../images/test1.jpg')} className='w-1/2 rounded-xl'/> */}
              {/* <p className='text-sm w-1/2 font-Playfair
                            md:text-lg'>
              The very essence of our name, "Prashanti," encapsulates our profound mission – to provide the pinnacle of serenity and tranquility through our comprehensive medical services.
              </p>
            </div>


            <p className='text-sm font-Playfair
                          md:text-lg'>
            Our evolution from a simple pharmacy to the esteemed establishment we stand as today has been marked by relentless dedication, tireless efforts, and the unwavering support of countless individuals. It's through the collective commitment of our team, the trust of our patrons, and the encouragement of our community that we've grown, step by step.
            </p>

            <h1 className='text-3xl font-EB font-bold text-slate-700
                            md:text-5xl border-b-2 w-full py-4 border-slate-800'>
                              The Journey
            </h1>

            <div className='flex gap-2 items-end'>
              <p className='w-1/2 text-sm font-Playfair
                            md:text-lg'>
              In the year 2020, a new chapter was etched into our journey. We extended our horizons to encompass a state-of-the-art diagnostic center and a clinic for esteemed doctors – a testament to our unwavering devotion to offering holistic care. Within the embrace of our establishment, we merged a pharmacy shop and a sanctuary for generic medicines.
              </p>

              <img src={require('../images/test1.jpg')} className='w-1/2 rounded-lg md:h-96'/>
            </div>

              <p className='text-sm font-Playfair
                            md:text-lg'>
              Fast forward to 2023, and we're delighted to introduce the digital extension of Prashanti through this website. A simple click allows you to request the comfort of home blood test collection, while the same digital gateway offers seamless scheduling of appointments with our esteemed doctors.

              Our website also unveils the varied timings of our doctors, ensuring unhindered access to care, with each visit optimized to align with your schedule. This platform exemplifies our commitment to harmonizing modern technology with traditional healing wisdom.
              </p>

              <div className='p-4 flex flex-col gap-5 border-y-2 border-slate-800'>
                  <p className='text-center font-Playfair'>
                  Prashanti isn't merely a name,it's a symbol of the highest peace that every individual deserves. We invite you to explore our digital haven, engage with our services, and join us in our endeavor to nurture well-being and tranquility.

                  </p>

                  <p className='text-center font-Playfair font-bold'>
                  Thank you for being an integral part of the Prashanti story.

                  With serenity and healing,
                  The Prashanti Family
                  </p>
              </div>

              <h1 className='text-3xl font-EB font-bold text-slate-700
                                md:text-5xl'>
                                  Location
              </h1>
              <p className='text-sm font-Playfair text-slate-600'>You can find us near the post office, adjacent to the old bridge, Singtam, East Sikkim. This has been our home since 2020, where we've been dedicated to bringing you the highest level of peace and care in healthcare. </p>
          
        </div>
        <Footer/>
      </div> */}

export default Aboutus