import React,{useState,useEffect} from 'react'
import { getDocs,collection,updateDoc, doc} from 'firebase/firestore'
import {database} from '../../firebase/firebaseConfig'
import Navbar from '../components/Navbar'
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { MenuItem } from '@mui/material';
import Button from "@mui/material/Button";
import BackButton from '../components/BackButton';


const EditCard = ({name,role,id,time,availability,onChangeHandler,setEdditButton,updateFile}) =>(
  <div className='absolute z-10  p-4 mt-20 flex justify-center items-center w-full h-full'>
            <Box sx={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',gap:2,width:'340px',height:"380px",backgroundColor:'white',boxShadow:10}}>
              <h1>{name}</h1>
              <h1>{role}</h1>
            <TextField
                    id='time'
                    label='setTiming'
                    variant="outlined"
                    required={true}
                    value={time}
                    onChange={onChangeHandler}
            />
            <TextField
                    id='availability'
                    name='availability'
                    select
                    label='availability'
                    // defaultValue="Male"
                    sx={{marginTop:'20px',width:'200px'}}
                    value={availability}
                    onChange={onChangeHandler}
                >
                    <MenuItem  name='availability' value='On Duty'>On Duty</MenuItem>
                    <MenuItem  name='availability' value='Off Duty'>Off Duty</MenuItem>
              </TextField>
              <Button variant="contained"  onClick={() => updateFile(id)}>Update</Button>
              <Button variant="contained"  onClick={()=>setEdditButton(false)}>Back</Button>
            </Box>

  </div>
)

const DoctorCard = ({name,role,time,availability,id,handleEditClcik}) =>{
  
  return(
    <div className='border-[1px] border-gray-200 relative flex h-48 shadow-lg p-2 white'>
      
              <div className='flex flex-col w-[70%]'>
              <h1 className='text-xl font-bold'>{name}</h1>
              <span className='text-sm'>{role}</span>
              <h1 className='font-bold'>Availablility</h1>
              <span className={`${availability === 'On Duty'? 'bg-green-400':'bg-red-400'} w-[50%] p-1 rounded-lg`}>{availability === ''? 'undefined': availability}</span>
              <h1 className='font-bold'>Timing</h1>
              <span>{time === ''?'undefined': time}</span>
              </div>

              <div className='flex justify-center items-center w-[30%]'>
              <Button variant="contained"  onClick={() => handleEditClcik(name,role,id,time,availability)}>
              Edit
              </Button>
              </div>
       
      
    </div>
  )
}

const DocTiming = () => {

  const collRef = collection(database,'DoctorData')
  const [doctorData,setDoctorData] = useState([])
  const [editButton,setEdditButton] = useState(false)

  useEffect(() =>{
    let temp = []
    const unsubscribe = async () =>{
      await getDocs(collRef)
      .then(snapshot =>{
        snapshot.docs.forEach(doc =>{
          temp.push({...doc.data(),id:doc.id})
        })
        // console.log(temp)
        setDoctorData(temp)
      })
    }
    unsubscribe()

    return() =>{
      unsubscribe()
    }
  },[editButton])

  const [selectedDoctor,setSelectedDoctor] = useState({
    name:'',
    role:'',
    id:'',
    time:'',
    availability:'',
  })
  
  const handleEditClcik = (name,role,id,time,availability) =>{
    // console.log(name)
    setEdditButton(true)
    setSelectedDoctor(prev =>({
      ...prev,
      name:name,
      role:role,
      id:id,
      time:time,
      availability:availability
    }))
  }

  const onChangeHandler = (e) =>{
    const {id,value,name} = e.target

    if(name === 'availability'){
      setSelectedDoctor(prev =>({
        ...prev,
        [name]:value
      }))
    }
    else{

      setSelectedDoctor(prev =>({
        ...prev,
        [id]:value
      }))
    }

    // console.log(selectedDoctor)
  }

  const updateFile = async (id) =>{
    const docRef = doc(database,'DoctorData',id) 
    await updateDoc(docRef,{
      time:selectedDoctor.time,
      availability:selectedDoctor.availability
    }).then(() => setEdditButton(false))
  }
 

  // console.log(doctorData)

  return (
    <div className='relative'>

      <div>
        <h1 className='text-2xl px-2 font-bold tracking-tighter mt-10'>Edit Availablity Timing</h1>
      </div>

      {
        editButton && 
        <EditCard 
        name={selectedDoctor.name} 
        role={selectedDoctor.role} 
        id={selectedDoctor.id}
        time={selectedDoctor.time}
        setEdditButton={setEdditButton}
        availability={selectedDoctor.availability}
        onChangeHandler={onChangeHandler}
        updateFile={updateFile}
        />
      }

      <div className={`${editButton ? 'blur-sm':''}  p-2 grid grid-cols-3 gap-4`}>
        {
          doctorData.map(doctor =>(
            <DoctorCard 
              name={doctor.name}
              role={doctor.role}
              availability={doctor.availability}
              time={doctor.time}
              id={doctor.id}
              editButton={editButton}
              handleEditClcik={handleEditClcik}
            />
          ))
        }
      </div>
    </div>
  )
}

export default DocTiming