import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

export default function Refund(){
    return(
        <div>
            <Navbar/>
            <div className="mt-20">
            <div className= 'compalinace-page'>
            <div className= 'header-conatiner'>
                        <div className= 'merchant-logo'>
                <div className= 'logo-container'><div className= 'logo-text'>P</div></div>
            </div>
                                        <p className= 'header-text'>Prashanti Diagonistics</p>
        </div>
            <div className='compalinace-content'>
                                                    <div className='content-container'>
    <p className='content-head'>Cancellation &amp; Refund Policy</p>
    <div className='content-seprater'></div>
    <p className='updated-date'>Last updated on Dec 18th 2023</p>
            <p className='content-text'>
            Prashanti Diagonistics believes in helping its customers as far as possible,
            and has therefore a liberal cancellation policy. Under this policy:
        </p>
        <ul className='unorder-list'>
            <li className='list-item'>
                <p className='content-text list-text'>
                    Cancellations will be considered only if the request is made within
                    same day of placing the order. However, the cancellation request
                    may not be entertained if the orders have been communicated to
                    the vendors/merchants and they have initiated the process of shipping them.
                </p>
            </li>
            <li className='list-item'>
                <p className='content-text list-text'>
                    Prashanti Diagonistics does not accept cancellation
                    requests for perishable items like flowers, eatables etc.
                    However, refund/replacement can be made if the customer establishes
                    that the quality of product delivered is not good.
                </p>
            </li>
            <li className='list-item'>
                <p className='content-text list-text'>
                    In case of receipt of damaged or defective items please report the
                    same to our Customer Service team. The request will, however,
                    be entertained once the merchant has checked and determined the
                    same at his own end. This should be reported within
                    same day of receipt of the
                    products.
                </p>
            </li>
            <li className='list-item'>
                <p className='content-text list-text'>
                    In case you feel that the product received is not as shown on the site
                    or as per your expectations, you must bring it to the notice of our
                    customer service within same day of
                    receiving the product. The Customer Service Team after looking into your
                    complaint will take an appropriate decision.
                </p>
            </li>
            <li className='list-item'>
                <p className='content-text list-text'>
                    In case of complaints regarding products that come with a warranty from manufacturers, please refer the issue to them.
                </p>
            </li>
            <li className='list-item'>
                <p className='content-text list-text'>
                    In case of any Refunds approved by the
                    Prashanti Diagonistics, it’ll take
                    3-5 days for the refund to
                    be processed to the end customer.
                </p>
            </li>
        </ul>
        </div>
                                 </div>
            </div>
            </div>

            <Footer/>
        </div>
    )
}