import React from 'react'
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    page: {
      fontFamily: 'Helvetica',
      fontSize: 12,
      padding: 20,
      display:'flex',
      flexDirection: 'column',
    },
    header: {
      textAlign: 'center',
      marginBottom: 20,
      fontSize:40
    },

    subHeader: {
        display:'flex',
        flexDirection:'column',
        textAlign: 'center',
        marginBottom: 20,
        fontSize:20
      },
      billNo:{
        textAlign: 'center',
        marginBottom: 20,
        fontSize:20,
        fontWeight:'bold',
        borderWidth:2
      },
    columnContainer: {
      display:'flex',
      flexDirection: 'column',
      marginBottom: 8,
    },
    rowContainer:{
        display:'flex',
        flexDirection: 'row',
        gap:5
    },
    label: {
      fontSize: 16,
      fontWeight: 'bold',
    },
    labelP: {
      fontSize: 25,
      fontWeight: 'bold',
    },
    value: {
      fontSize: 16,
    },
    itemsTable: {
      width: '100%',
      marginTop: 20,
    },
    tableRow: {
      display:'flex',
      flexDirection: 'row',
      borderBottomColor: '#000',
      borderBottomWidth: 1,
      padding: 8,
    },
    tableCell: {
      width: '25%',
    },
    multiCell:{
        width:'100%'
    },
    totalAmount: {
      fontSize: 16,
      fontWeight: 'bold',
      marginTop: 20,
    },
  });

const BillPdf = ({billData,resetBill}) => {

    console.log(billData)

    const printDiv = (divName) => {
        const printContents = document.getElementById(divName).innerHTML;
        const originalContents = document.body.innerHTML;
    
        document.body.innerHTML = printContents;
        window.print();
    
        document.body.innerHTML = originalContents;
    }

  return (

    <div>
        <div id='printable-content' className='border-2 border-black'>
        <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.header}>
            <Text>Prashanti Diagnostics Invoice</Text>
            </View>

            <View style={styles.subHeader}>
            <Text>Near Post Office,Singtam,East Sikkim</Text>
            <Text>GST:11AONPC0596G276</Text>
            <Text>Phone no:+918340754624, +918918901657, +919593372452</Text>
            <Text>Website: www.prashantidiagnostic.com</Text>
            </View>

            <View style={styles.billNo}>
            <Text style={styles.label}>Bill no:</Text>
            <Text style={styles.value}>{billData.billNum}</Text>
            </View>

            <View style={styles.rowContainer}>
            <Text style={styles.labelP}>L{billData.patientData.patientNumber}</Text>
            </View>

            <View style={styles.rowContainer}>
            <Text style={styles.label}>Reg no:</Text>
            <Text style={styles.value}>{billData.billNum}</Text>
            </View>


            <View style={styles.rowContainer}>
            <Text style={styles.label}>Patient Name:</Text>
            <Text style={styles.value}>{billData.patientData.firstName} {billData.patientData.lastName}</Text>
            </View>

            <View style={styles.rowContainer}>
            <Text style={styles.label}>Gender:</Text>
            <Text style={styles.value}>{billData.patientData.gender}</Text>
            </View>

            <View style={styles.rowContainer}>
            <Text style={styles.label}>Referred By:</Text>
            <Text style={styles.value}>{billData.refDoc}</Text>
            </View>

            <View style={styles.rowContainer}>
            <Text style={styles.label}>Date:</Text>
            <Text style={styles.value}>{billData.patientData.date}</Text>
            </View>
            <View style={styles.itemsTable}>
            <View style={styles.tableRow}>
                <Text style={styles.tableCell}>Description</Text>
                <Text style={styles.tableCell}>Quantity</Text>
                <Text style={styles.tableCell}>Total</Text>
            </View>
            {billData.UserTest.map((item, index) => (
                <View style={styles.tableRow} key={index}>
                <Text style={styles.tableCell}>{item.description}</Text>
                    
                    <Text style={styles.tableCell}>
                    {item.test.map(name =>(
                        <h1>{name}</h1> 
                    ))}
                    </Text>
                    

                <Text style={styles.tableCell}>{billData.estimate[item.description]}</Text>
                </View>
            ))}

            </View>
            <View style={styles.columnContainer}>
            <Text style={styles.totalAmount}>Total Amount:</Text>
            <Text style={styles.value}>Rs. {billData.estimate.total}</Text>
            <Text style={styles.totalAmount}>Payment Method:</Text>
            <Text style={styles.value}>{billData.patientData.paymentMethod}</Text>
            </View>
        </Page>
        </Document>
        </div>
       
       <div className='flex gap-2 mt-5'>
        <button
          type="button"
          onClick={() => printDiv('printable-content')}
          className="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-10 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="-ml-0.5 h-6 w-6" aria-hidden="true">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z" />
            </svg>

            Print
          </button>

          <button
          type="button"
          onClick={() =>resetBill()}
          className="inline-flex items-center gap-x-2 rounded-md border-indigo-600 border-2 text-indigo-600 px-3.5 py-2.5 text-sm font-semibold shadow-sm hover:bg-indigo-500/10 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="-ml-0.5 w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>


            Close
          </button>
       </div>
        
    </div>
  )
}

export default BillPdf