import React from 'react'

const UserDataForm = ({userData,setUserData,handleSubmit}) => {

    const changeHandler = (e) =>{
        const {name,id,value} = e.target
        
        setUserData(prev =>({
            ...prev,
            [name === 'gender'?name:id]:value !== 'on' ? value : id
        }))
        // console.log(userData)
    }

  return (
    <div className='px-2 mt-28'>
        <form onSubmit={handleSubmit}>
            <div class="space-y-12">

                <div class="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                <div>
                    <h2 class="text-base font-semibold leading-7 text-gray-900">Personal Information</h2>
                    <p class="mt-1 text-sm leading-6 text-gray-600">Use a permanent address where you can receive mail.</p>
                </div>

                <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">

                    <div class="col-span-full">
                    <label for="phoneNumber" class="block text-sm font-medium leading-6 text-gray-900">Phone Number</label>
                    <div class="mt-2">
                        <input 
                        onChange={changeHandler} 
                        value={userData.phoneNumber} 
                        type="text" 
                        name="phoneNumber" 
                        id="phoneNumber" 
                        autocomplete="Phone Number" 
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                    </div>
                    </div>

                    <div className="col-span-full">
                    <label for="street-address" className="block text-sm font-medium leading-6 text-gray-900">Street address</label>
                    <div className="mt-2">
                        <input 
                        onChange={changeHandler} 
                        value={userData.streetAddress}
                        type="text" 
                        name="streetAddress" 
                        id="streetAddress" 
                        autocomplete="street-address" 
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                    </div>
                    </div>

                    <div className="sm:col-span-2 sm:col-start-1">
                    <label for="age" className="block text-sm font-medium leading-6 text-gray-900">Age</label>
                    <div className="mt-2">
                        <input 
                        onChange={changeHandler} 
                        value={userData.age} 
                        type="number" 
                        name="age" 
                        id="age" 
                        autocomplete="age" 
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                    </div>
                    </div>

                    <div>
                    <label className="text-base font-semibold text-gray-900">Gender</label>
                    <p className="text-sm text-gray-500">Select your gender</p>
                    <fieldset className="mt-4">
                        <legend className="sr-only">Gender</legend>
                        <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                        <div className="flex items-center">
                            <input 
                            onChange={changeHandler} id="male" name="gender" type="radio" className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"/>
                            <label for="male" className="ml-3 block text-sm font-medium leading-6 text-gray-900">Male</label>
                        </div>
                        <div className="flex items-center">
                            <input onChange={changeHandler} id="female" name="gender" type="radio" className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"/>
                            <label for="female" className="ml-3 block text-sm font-medium leading-6 text-gray-900">female</label>
                        </div>
                        <div className="flex items-center">
                            <input onChange={changeHandler} id="others" name="gender" type="radio" className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"/>
                            <label for="others" className="ml-3 block text-sm font-medium leading-6 text-gray-900">others</label>
                        </div>
                        </div>
                    </fieldset>
                    </div>
                </div>
                </div>

            </div>

            <div className="mt-6 flex items-center justify-end gap-x-6">
                <button type="button" className="text-sm font-semibold leading-6 text-gray-900">Cancel</button>
                <button type="submit" className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Save</button>
            </div>
            </form>
    </div>
  )
}

export default UserDataForm