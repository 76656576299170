import React, { useState } from 'react'
import { 
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Checkbox
     } from '@mui/material';
     import Button from "@mui/material/Button";

const UserTestSelection = ({userData,userTest,handleTestDetails,test,handleEstimate,setSteps}) => {

    const [error,setError] = useState(false)

    const nextStep = () =>{
        let flag = false
        for(const key in userTest){
            if(userTest[key].length > 0)flag = true
        }

        if(flag === true){
            setSteps(1)
        }
        else{
            setError(true)
            setTimeout(() =>{
                setError(false)
            },5000)
        }
    }

  return (
    <div className='px-6 md:px-8'>
        <h1 className='font-bold text-2xl my-4 md:text-4xl text-slate-800'>Book a bloodtest</h1>

        <h1 className='text-gray-500'>Select type of bloodtest</h1>
        <div className='flex flex-wrap gap-4 my-4'>
        <FormControl sx={{ minWidth: 300 }}>
            <InputLabel id="lab">lab</InputLabel>
            <Select
            labelId='lab'
            name='lab'
            value={userTest['lab']}
            onChange={handleTestDetails}
            autoWidth
            multiple
            label='lab'
            >
                {
                    test !== null && test.lab.tests.map(labItem =>(
                        <MenuItem 
                        value={labItem.name}
                        key={labItem.name} 
                        id={labItem.name}
                        sx={{width:300}}
                        onClick={() =>handleEstimate(labItem.name,labItem.price)}
                        // onClick={() => estimationFunc(labItem.name,labItem.price,item)}
                        >
                            <span className='text-xl'>{labItem.name}</span>-<span className='text-gray-500'>Rs.{labItem.price}</span>
                        </MenuItem>
                    ))
                }
            </Select>
            </FormControl>

            <FormControl sx={{ minWidth: 300 }}>
            <InputLabel id="usg">usg</InputLabel>
            <Select
            labelId='usg'
            name='usg'
            value={userTest['usg']}
            onChange={handleTestDetails}
            autoWidth
            multiple
            label='usg'
            >
                {
                    test !== null && test.usg.tests.map(labItem =>(
                      <MenuItem 
                      value={labItem.name}
                      key={labItem.name} 
                      id={labItem.name}
                      sx={{width:300}}
                      onClick={() =>handleEstimate(labItem.name,labItem.price)}
                      // onClick={() => estimationFunc(labItem.name,labItem.price,item)}
                      >
                          <span className='text-xl'>{labItem.name}</span>-<span className='text-gray-500'>Rs.{labItem.price}</span>
                      </MenuItem>
                    ))
                }
            </Select>
            </FormControl>

            <FormControl sx={{ minWidth: 300 }}>
            <InputLabel id="ecg">ecg</InputLabel>
            <Select
            labelId='ecg'
            name='ecg'
            value={userTest['ecg']}
            onChange={handleTestDetails}
            autoWidth
            multiple
            label='ecg'
            >
                {
                    test !== null && test.ecg.tests.map(labItem =>(
                      <MenuItem 
                      value={labItem.name}
                      key={labItem.name} 
                      id={labItem.name}
                      sx={{width:300}}
                      onClick={() =>handleEstimate(labItem.name,labItem.price)}
                      // onClick={() => estimationFunc(labItem.name,labItem.price,item)}
                      >
                          <span className='text-xl'>{labItem.name}</span>-<span className='text-gray-500'>Rs.{labItem.price}</span>
                      </MenuItem>
                    ))
                }
            </Select>
            </FormControl>

            <FormControl sx={{ minWidth: 300 }}>
            <InputLabel id="digitalXray">digitalXray</InputLabel>
            <Select
            labelId='digitalXray'
            name='digitalXray'
            value={userTest['digitalXray']}
            onChange={handleTestDetails}
            autoWidth
            multiple
            label='digitalXray'
            >
                {
                    test !== null && test.digitalXray.tests.map(labItem =>(
                      <MenuItem 
                      value={labItem.name}
                      key={labItem.name} 
                      id={labItem.name}
                      sx={{width:300}}
                      onClick={() =>handleEstimate(labItem.name,labItem.price)}
                      // onClick={() => estimationFunc(labItem.name,labItem.price,item)}
                      >
                          <span className='text-xl'>{labItem.name}</span>-<span className='text-gray-500'>Rs.{labItem.price}</span>
                      </MenuItem>
                    ))
                }
            </Select>
            </FormControl>

            <FormControl sx={{ minWidth: 300 }}>
            <InputLabel id="xray">xray</InputLabel>
            <Select
            labelId='xray'
            name='xray'
            value={userTest['xray']}
            onChange={handleTestDetails}
            autoWidth
            multiple
            label='xray'
            >
                {
                    test !== null && test.xray.tests.map(labItem =>(
                      <MenuItem 
                      value={labItem.name}
                      key={labItem.name} 
                      id={labItem.name}
                      sx={{width:300}}
                      onClick={() =>handleEstimate(labItem.name,labItem.price)}
                      // onClick={() => estimationFunc(labItem.name,labItem.price,item)}
                      >
                          <span className='text-xl'>{labItem.name}</span>-<span className='text-gray-500'>Rs.{labItem.price}</span>
                      </MenuItem>
                    ))
                }
            </Select>
            </FormControl>

            <FormControl sx={{ minWidth: 300 }}>
            <InputLabel id="ctscan">ctscan</InputLabel>
            <Select
            labelId='ctscan'
            name='ctscan'
            value={userTest['ctscan']}
            onChange={handleTestDetails}
            autoWidth
            multiple
            label='ctscan'
            >
                {
                    test !== null && test.ctscan.tests.map(labItem =>(
                      <MenuItem 
                      value={labItem.name}
                      key={labItem.name} 
                      id={labItem.name}
                      sx={{width:300}}
                      onClick={() =>handleEstimate(labItem.name,labItem.price)}
                      // onClick={() => estimationFunc(labItem.name,labItem.price,item)}
                      >
                          <span className='text-xl'>{labItem.name}</span>-<span className='text-gray-500'>Rs.{labItem.price}</span>
                      </MenuItem>
                    ))
                }
            </Select>
            </FormControl>

            <FormControl sx={{ minWidth: 300 }}>
            <InputLabel id="outsource">outsource</InputLabel>
            <Select
            labelId='outsource'
            name='outsource'
            value={userTest['outsource']}
            onChange={handleTestDetails}
            autoWidth
            multiple
            label='outsource'
            >
                {
                    test !== null && test.outsource.tests.map(labItem =>(
                      <MenuItem 
                      value={labItem.name}
                      key={labItem.name} 
                      id={labItem.name}
                      sx={{width:300}}
                      onClick={() =>handleEstimate(labItem.name,labItem.price)}
                      // onClick={() => estimationFunc(labItem.name,labItem.price,item)}
                      >
                          <span className='text-xl'>{labItem.name}</span>-<span className='text-gray-500'>Rs.{labItem.price}</span>
                      </MenuItem>
                    ))
                }
            </Select>
            </FormControl>
        </div>

        <div className="relative">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center">
                <span className="bg-white px-2 text-gray-500">
                {/* <PlusIcon className="h-5 w-5 text-gray-500" aria-hidden="true" /> */}
                <h1>Prashanti</h1>
                </span>
            </div>
        </div>

      <div className="px-4 sm:px-0">
        <h3 className="text-base font-semibold leading-7 text-gray-900">Applicant Information</h3>
        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Personal details and application.</p>
      </div>
      <div className="mt-6 border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Full name</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{userData && userData.username}</dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Application for</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">Blood Test</dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Email address</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{userData &&  userData.email}</dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Age</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{userData && userData.age}</dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Phone Number</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{userData && userData.phoneNumber}</dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Address</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {userData && userData.streetAddress}
            </dd>
          </div>
        </dl>
      </div>

                <div className='w-full flex flex-wrap justify-center items-center md:justify-end py-5 gap-4'>
                  {error ? <h1 className='text-red-400 text-sm'>You havent selectes any test!</h1>:''}<Button variant='contained' sx={{minWidth:250}} onClick={nextStep}>Submit</Button>
                </div>
    </div>
  )
}

export default UserTestSelection