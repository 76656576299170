import React,{useEffect, useState} from 'react'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import { addDoc,arrayUnion,collection, doc, getDoc, getDocs,increment, setDoc, updateDoc } from 'firebase/firestore';
import { database } from '../../firebase/firebaseConfig';
import BillPdf from '../components/BillingComponents/BillPdf';
import { Button } from '@mui/material';


const patient = {
    title:'',
    firstName:'',
    lastName:'',
    gender:'',
    age:0,
    phoneNumber:'91',
    address:'',
    history:'',
    date:'',
    patientNumber:0,
    paymentMethod:'',
    remarks:''
}

const testNames = {
    digitalXray:false,
    xray:false,
    lab:false,
    usg:false,
    outsource:false,
    ecg:false,
    ctscan:false,
}

const testObject = {
    digitalXray:[],
    xray:[],
    lab:[],
    usg:[],
    outsource:[],
    ecg:[],
    ctscan:[],
}

const notificationMethods = [
    { id: 'UPI', title: 'UPI' },
    { id: 'CASH', title: 'CASH' },
    { id: 'CARD', title: 'CARD' },
  ]

//fix userTest optimize states

const Billing = () => {

    const [patientData,setPatientData] = useState(patient)
    const [refDoc,setRefDoc] = useState('')
    const [selected,setSelected] = useState(testNames)
    const [toDisplayTest,setDisplayTest] = useState([])
    const [testDetails,setTestDetils] = useState(testObject)
    const [estimate,setEstimate] = useState(0)
    const [displayBill,setDisplayBill] = useState(false)
    const [UserTest,setUserTest] = useState([])
    const [billNum,setBillNum] = useState(0)
    const [patientNumber,setPatientNumber] = useState(0)
    const [labData,setLabData] = useState(null)
    const [discount,setDiscount] = useState(null)
    const [discountValue,setValue] = useState(false)

    const currentDate = new Date();
    const formattedDate = currentDate.toDateString();

    useEffect(() =>{
        const getData= async() =>{
            try{
                const statBillRef = doc(database,'Stats','Bills')
                const statPatientRef = doc(database,'Stats','patientNumber')
                const testRef = collection(database,'LabTest')
                
                await getDoc(statBillRef)
                .then(async (snapshot) =>{
                    const temp = snapshot.data()
                    setBillNum(temp.billNumber)
                })
                await getDoc(statPatientRef)
                .then((snapshot)=>{
                    const temp = snapshot.data()
                    if(temp.date !== formattedDate){
                        updateDoc(statPatientRef,{
                            pNum:0,
                            date:formattedDate
                        })
                        setPatientNumber(temp.pNum)
                    }
                    else{
                        setPatientNumber(temp.pNum)
                        const copyData = {...patientData,patientNumber:patientNumber}
                        setPatientData(copyData)
                    }
                })

                await getDocs(testRef)
                .then((snapshot) =>{
                    let copyData = {}
                    snapshot.forEach(doc =>{
                        copyData[doc.id] = doc.data()
                    })
                    setLabData(copyData)
                })
            }
            catch(err){
                console.log(err)
            }
        }

        getData()
    },[])

    useEffect(() =>{
        let userTest = []
        let count = 0
        for(const key in testDetails){
            if(testDetails[key].length > 0){
                userTest[count] = {description:key,test:testDetails[key]}
                count++
            }
        }
        setUserTest(userTest)

    },[testDetails])

    const applyDiscount = () =>{
        const copyEstimate = estimate.total

        const Value = copyEstimate * (discount/100)
        const discount_value = copyEstimate - Value
  
        setValue(parseInt(discount_value))
    }

    const removeDiscount = () =>{
        setDiscount(null)
        setValue(false)
    }


    const addDataToDatabase = async() =>{
        const collRef = collection(database,'StoreBilling')
        const statBillRef = doc(database,'Stats','Bills')
        const statPatientRef = doc(database,'Stats','patientNumber')

        
        
        try{ 

            const updatedPatientData = {...patientData,patientNumber:patientNumber + 1,date:formattedDate}
            const updatedBllNumber = billNum + 1
            const updatedEstimate = discountValue ? {...estimate,total:discountValue} : estimate
            setEstimate(updatedEstimate)
            


            
            addDoc(collRef,{
                patientData:updatedPatientData,
                refDoc,
                UserTest,
                estimate:updatedEstimate,
                billNum:updatedBllNumber,
            })
            .then(() =>{
                updateDoc(statBillRef,{
                    billNumber:increment(1)
                })
                .then(() =>{
                    updateDoc(statPatientRef,{
                        pNum:increment(1)
                    })
                    setBillNum(prev => prev + 1)
                    setPatientNumber(prev => prev + 1)
                    const duplicatePNum = patientNumber + 1
                    setPatientData(prev => ({...prev,patientNumber:duplicatePNum}))
                    setDisplayBill(true)
                })
            })
            const currentDate = new Date()
            const options = { year: 'numeric', month: 'long' };
            const options2 = {year:'numeric'}

            const formattedDate2 = currentDate.toLocaleDateString(undefined, options);
            const year = currentDate.toLocaleDateString(undefined,options2)

            const revRef = doc(database,'Revenue',formattedDate2)
            const annualRef = doc(database,'Revenue','Annual')
            const price = estimate.total
            
            setDoc(revRef,{
                data:arrayUnion({date:formattedDate,price})
            },{merge:true}) 
            
            setDoc(annualRef,{
                [year]:arrayUnion(price)
            },{merge:true}) 
        }
        catch(err){
            console.log(err)
        }
    }

    const addDisplay = (name) =>{
        setSelected(prev =>({...prev,[name]:!prev[name]}))

        const check = toDisplayTest.findIndex(item => item === name)
        let temp = toDisplayTest
        
        if(check === -1) temp.push(name)
        if(check !== -1){

            temp.splice(check,1)
            setDisplayTest(temp)
            setEstimate(prev =>({
                ...prev,
                [name]:0,
                total:prev.total !== undefined? prev.total - prev[name] : 0
            }))
            setTestDetils(prev =>({
                ...prev,
                [name]:[]
            }))
        }
        setDisplayTest(temp)
    }

    const estimationFunc = (name,cost,item) =>{
        const check = testDetails[item].find(test => test === name)
        let intCost = parseInt(cost)
        if(check === name){
            setEstimate(prev =>({
                ...prev,
                [item]:prev[item] - intCost,
                total:prev.total !== undefined ? prev.total - intCost : 0
            }))
        }
        else{
            setEstimate(prev =>({
                ...prev,
                [item]:prev[item] !== undefined? prev[item] + intCost : intCost,
                total:prev.total !== undefined ? prev.total + intCost : intCost
            }))
        }
    }

    const handleTestDetails = (e) =>{
        const {name} = e.target
        setTestDetils(prev => ({...prev,[name]:e.target.value}))  
    }


    const keyValuePairArray = Object.entries(estimate);

    const TestButton = ({typeName,toggle,name}) =>{

        return(
            <button 
            className={`w-28 h-28 rounded-lg border-2 shadow-2xl  ${toggle ? 'bg-blue-500/50':'border-slate-400 bg-blue-100/50 hover:bg-white/50'}`}
            onClick={() =>addDisplay(name)}
            >
                {typeName}
            </button>
        )
    }

    const FieldInputs = ({item,data}) =>{

        return(
            <FormControl sx={{ maxWidth: 500 }}>
            <InputLabel id="title">{item}</InputLabel>
            <Select
            labelId={item}
            name={item}
            value={testDetails[item]}
            onChange={handleTestDetails}
            autoWidth
            multiple
            label={item}
            >
                {
                    data.map(labItem =>(
                        <MenuItem 
                        value={labItem.name} 
                        id={item}
                        onClick={() => estimationFunc(labItem.name,labItem.price,item)}
                        >
                            {labItem.name}
                        </MenuItem>
                    ))
                }
            </Select>
            </FormControl>
        )
    }

    const handlePatientChange = (e) =>{
        const {id,value,name} = e.target
        const keyName = id ? id : name
        setPatientData(prev =>({
            ...prev,
            [keyName]:value
        }))
    }

    const handlePaymentMethod = (e) =>{
        const {id,name} = e.target

        setPatientData(prev =>({
            ...prev,
            [name]:id
        }))
    }

    const handlePhoneNumber = (value) =>{
        setPatientData(prev => ({
            ...prev,
            phoneNumber:value
        }))
    }

    const resetBill = () =>{
        setDisplayBill(false)
        setPatientData(patient)
        setRefDoc('')
        setSelected(testNames)
        setDisplayTest([])
        setTestDetils(testObject)
        setEstimate(0)
        setUserTest([])
        setDiscount(null)
        setValue(false)
    }



  return (
    <div className='flex relative'>


        {
        displayBill ? (
            <div className='w-full  h-full absolute  transform transition-all duration-200 opacity-100 z-10'>
            <div className='relative bg-white w-full h-full'>
                <BillPdf billData={{patientData,refDoc,UserTest,estimate,billNum}} resetBill={resetBill}/>
            </div>
        </div>
        ):
        ''
        }

        <div className='w-3/4 p-5'>
            <section className='flex flex-col gap-5'>
                <h1 className="w-1/3 rounded-md bg-indigo-50 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm">{formattedDate}</h1>
                <h1 className='text-2xl font-semibold'>Patient Details</h1>


                {/* patient name and other stuff */}
                <section className='flex gap-2'>
                    <FormControl sx={{ minWidth: 80 }}>
                        <InputLabel id="title">Title</InputLabel>
                        <Select
                        labelId="title"
                        id="title"
                        name='title'
                        value={patientData.title}
                        onChange={handlePatientChange}
                        autoWidth
                        label="Title"
                        >
                        <MenuItem value='Mr.'>Mr.</MenuItem>
                        <MenuItem value='Mrs.'>Mrs.</MenuItem>
                        <MenuItem value='MS'>Ms</MenuItem>
                        </Select>
                    </FormControl>

                    <TextField
                        id='firstName'
                        label='FirstName'
                        value={patientData.firstName}
                        onChange={handlePatientChange}
                    />

                    <TextField
                        id='lastName'
                        label='LastName'
                        value={patientData.lastName}
                        onChange={handlePatientChange}
                    />

                    
                </section>

                {/* address and patient history */}
                <section className='flex flex-col gap-2'>

                    
                    <div className='flex gap-2'>
                        <TextField
                            id='age'
                            label='Age'
                            sx={{width: 150 }}
                            value={patientData.age}
                            onChange={handlePatientChange}
                            type='number'
                        />
                        <FormControl sx={{minWidth: 150 }}>
                            <InputLabel id="gender">Gender</InputLabel>
                            <Select
                            labelId="gender"
                            id="gender"
                            name='gender'
                            value={patientData.gender}
                            onChange={handlePatientChange}
                            autoWidth
                            label="Gender"
                            >
                            <MenuItem value='Male'>Male</MenuItem>
                            <MenuItem value='Female'>Female</MenuItem>
                            <MenuItem value='Others'>Others</MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                    <PhoneInput
                        country={'in'}
                        value={patientData.phoneNumber}
                        id='phoneNumber'
                        onChange={phone => handlePhoneNumber(phone)}
                    />
                   
                    <TextField
                        id='address'
                        label='Address'
                        value={patientData.address}
                        onChange={handlePatientChange}
                        sx={{maxWidth:'55%'}}
                        multiline
                    />

                    <TextField
                        id='history'
                        label='Patient History'
                        value={patientData.history}
                        onChange={handlePatientChange}
                        sx={{maxWidth:'55%'}}
                        multiline
                    />
                </section>
                {/* end of patient details */}

                <h1 className='text-2xl font-semibold'>Case Details</h1>

                <section>
                    <h1 className='text-gray-600 py-2'>Reffered By</h1>
                    <FormControl sx={{ minWidth: 500 }}>
                        <InputLabel id="title">Doctor</InputLabel>
                        <Select
                        labelId="title"
                        name='refDoc'
                        value={refDoc}
                        onChange={(e) => setRefDoc(e.target.value)}
                        autoWidth
                        label="Doctor"
                        >
                        <MenuItem value='Dr.MP Sharma'>Dr.MP Sharma</MenuItem>
                        <MenuItem value='Dr.Test'>Dr.Test</MenuItem>
                        <MenuItem value='Dr.Test2'>Dr.Test2</MenuItem>
                        </Select>
                    </FormControl>


                    <section className='mt-10'>
                        <section className='flex w-2/3 flex-wrap gap-2'>
                            <TestButton
                                typeName='LAB'
                                toggle={selected.lab}
                                name='lab'
                            />

                            <TestButton
                            typeName='USG'
                            toggle={selected.usg}
                            name='usg'
                            />

                            <TestButton
                            typeName='Digital X-RAY'
                            toggle={selected.digitalXray}
                            name='digitalXray'
                            />

                            <TestButton
                            typeName='X-RAY'
                            toggle={selected.xray}
                            name='xray'
                            />

                            <TestButton
                            typeName='Outsource'
                            toggle={selected.outsource}
                            name='outsource'
                            />

                            <TestButton
                            typeName='ECG'
                            toggle={selected.ecg}
                            name='ecg'
                            />

                            <TestButton
                            typeName='CT Scan'
                            toggle={selected.ctscan}
                            name='ctscan'
                            />
                        </section>

                        <section className='flex flex-col gap-5 mt-10 w-full'>
                            {
                                toDisplayTest.map(item =>{
                                    const data = item === 'lab' ? labData.lab.tests: 
                                                 item === 'usg' ? labData.usg.tests:
                                                 item === 'ecg' ? labData.ecg.tests:
                                                 item === 'digitalXray' ? labData.digitalXray.tests:
                                                 item === 'xray'? labData.xray.tests:
                                                 item === 'outsource' ? labData.outsource.tests:
                                                 item === 'ctscan' ? labData.ctscan.tests: null

                                    return (
                                        <FieldInputs item={item} key={item} data={data}/>
                                    )
                                })
                            }
                        </section>
                    </section>
                </section>
                
                <div>
                <div>
                    <label className="text-base font-semibold text-gray-900">Payment method</label>
                    <p className="text-sm text-gray-500">Mode of payment done by customer</p>
                    <fieldset className="mt-4">
                        <legend className="sr-only">Notification method</legend>
                        <div className="space-y-4">
                        {notificationMethods.map((notificationMethod) => (
                            <div key={notificationMethod.id} className="flex items-center">
                            <input
                                id={notificationMethod.id}
                                name="paymentMethod"
                                type="radio"
                                value={patientData.paymentMethod}
                                onChange={handlePaymentMethod}
                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                            <label htmlFor={notificationMethod.id} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                                {notificationMethod.title}
                            </label>
                            </div>
                        ))}
                        </div>
                    </fieldset>
                </div>
                </div>

                <div>
                    
                    <label htmlFor='discount' className="block text-sm font-medium leading-6 text-gray-900">Add discount</label>
                    <div className='flex  gap-x-2'>
                        <TextField
                            id='discount'
                            type='number'
                            placeholder='Discount in %'
                            value={discount}
                            onChange={(e) => setDiscount(e.target.value)}
                        />
                        <Button variant='contained' onClick={applyDiscount}>Apply</Button>
                        <Button variant='outlined' onClick={removeDiscount}>Cancel</Button>
                    </div>
                <label htmlFor="comment" className="block text-sm font-medium leading-6 text-gray-900">
                    *Remarks
                </label>
                <div className="mt-2">
                    <textarea
                    rows={4}
                    name="remarks"
                    id="remarks"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    // defaultValue={''}
                    value={patientData.remarks}
                    onChange={handlePatientChange}
                    />
                </div>
                </div>
                
            </section>

            <button
                type="button"
                onClick={addDataToDatabase}
                className="mt-5 rounded-md bg-indigo-50 px-20 py-3.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
            >
                Create Case
            </button>
        </div>

        {/* billing info */}
        <div className='w-1/4 h-full fixed right-0  pt-10 px-2 '>
            <div className='relative p-5 bg-white w-full h-full shadow-2xl'>
               
               <h1  className='text-4xl tracking-tighter font-semibold text-red-400'>Payment Details</h1>

               <div className='flex flex-col gap-2 mt-10 min-h-[200px] p-2  bg-gray-100'>
                {
                    keyValuePairArray.map(([key,value]) =>{
                        if(key !== 'total')
                        return <h1 key={key} className=' text-lg text-gray-700 font-semibold uppercase'>{key}: {value}</h1>
                    })
                }

               </div>

               <h1 className='border-2 border-red-400 w-full mt-10'></h1>
                
                <section>
                    <h1 className='font-bold text-xl'>Total Amount:</h1>
                    {
                        discountValue ? 
                        (
                            <h1 className='text-xl'>Rs {discountValue} <span className='text-gray-500 text-xs'>(after {discount}% off)</span></h1>
                        ):
                        (
                            <h1 className='text-xl'>Rs { estimate.total === undefined ? 0 : estimate.total}</h1>
                        )
                    }
                    
                </section>
            </div>
        </div>
    </div>
  )
}

export default Billing