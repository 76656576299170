import React,{createContext,useContext,useState} from "react";

const StateContext = createContext()

const fields = {
    firstName : '',
    lastName : '',
    Address:'',
    phoneNumber: '',
    email:'',
    age:'',
    gender:'',
    date:'',
    test:[{
        id:''
    }],
    isApproved:false
}

export const ContextProvider = ({children}) =>{
    const [notificationBell,setBell] = useState(false)
    const [activeMenu,setActiveMenu] = useState(true)
    const [formData,setFormData] = useState(fields)
    

    return(
        <StateContext.Provider 
            value={{
                        notificationBell,setBell,
                        activeMenu,setActiveMenu,
                        formData,setFormData
                   }}
        >
            {children}
        </StateContext.Provider>
    )
}

export const useStateContext = () => useContext(StateContext)