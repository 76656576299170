import React from 'react'
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    page: {
      fontFamily: 'Helvetica',
      fontSize: 12,
      padding: 20,
      display:'flex',
      flexDirection: 'column',
    },
    header: {
      textAlign: 'center',
      marginBottom: 20,
    },
    columnContainer: {
      display:'flex',
      flexDirection: 'column',
      marginBottom: 8,
    },
    label: {
      fontSize: 12,
      fontWeight: 'bold',
    },
    value: {
      fontSize: 12,
      marginTop: 4,
    },
    itemsTable: {
      width: '100%',
      marginTop: 20,
    },
    tableRow: {
        display:'flex',
      flexDirection: 'row',
      borderBottomColor: '#000',
      borderBottomWidth: 1,
      padding: 8,
    },
    tableCell: {
      width: '25%',
    },
    totalAmount: {
      fontSize: 14,
      fontWeight: 'bold',
      marginTop: 20,
    },
  });

  const billData = {
    patientName: 'John Doe',
    date: 'October 10, 2023',
    items: [
      {
        description: 'Consultation Fee',
        quantity: 1,
        unitPrice: 100,
        total: 100,
      },
      {
        description: 'Lab Test',
        quantity: 2,
        unitPrice: 50,
        total: 100,
      },
      {
        description: 'X-Ray',
        quantity: 1,
        unitPrice: 75,
        total: 75,
      },
    ],
    totalAmount: 275,
  };  

const Test = () => {

    const testone = () =>{
        window.print()
    }
  return (
    <div>
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Text>Medical Clinic Invoice</Text>
        </View>
        <View style={styles.columnContainer}>
          <Text style={styles.label}>Patient Name:</Text>
          <Text style={styles.value}>{billData.patientName}</Text>
        </View>
        <View style={styles.columnContainer}>
          <Text style={styles.label}>Date:</Text>
          <Text style={styles.value}>{billData.date}</Text>
        </View>
        <View style={styles.itemsTable}>
          <View style={styles.tableRow}>
            <Text style={styles.tableCell}>Description</Text>
            <Text style={styles.tableCell}>Quantity</Text>
            <Text style={styles.tableCell}>Unit Price</Text>
            <Text style={styles.tableCell}>Total</Text>
          </View>
          {billData.items.map((item, index) => (
            <View style={styles.tableRow} key={index}>
              <Text style={styles.tableCell}>{item.description}</Text>
              <Text style={styles.tableCell}>{item.quantity}</Text>
              <Text style={styles.tableCell}>{item.unitPrice}</Text>
              <Text style={styles.tableCell}>{item.total}</Text>
            </View>
          ))}
        </View>
        <View style={styles.columnContainer}>
          <Text style={styles.totalAmount}>Total Amount:</Text>
          <Text style={styles.value}>{billData.totalAmount}</Text>
        </View>
      </Page>
    </Document>

    <button onClick={testone}>Test</button>
    </div>
  )
}

export default Test