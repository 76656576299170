import React,{useEffect,useState} from 'react'
import {doc, getDocs,collection, query,orderBy,where, updateDoc } from 'firebase/firestore';
import {database} from '../../firebase/firebaseConfig'
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {AiOutlineDown,AiOutlineDash} from 'react-icons/ai'
import axios from 'axios';



const collRef = collection(database,'BloodTestBookings')


function createData(name,age,date,email,address,test,gender,phoneNumber,id){
    
  return{
        name,
        address,
        date,
        gender,
        phoneNumber,
        id,
        email,
        detail:[
            {
                age:age,
                test:test
            }
        ]
    }
}


function RowNew(props) {
  const { row ,id} = props;
  const [open, setOpen] = React.useState(false);
  const [approveButton,setApproveButton] = useState(false);
  
  const approveHandle = async (id,email,name) =>{
    const docRef = doc(database,'BloodTestBookings',id)
    try {
      await axios.post('http://65.1.110.87:5001/notification/send-email',{name,email})
      .then(async () =>{
        console.log('email sent')
        await updateDoc(docRef,{
          approved:true
        }).then(setApproveButton(true))
        // .then(() =>{
    
        // })
      })
    } catch (error) {
      console.log(error)
    }
  }
 
  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <AiOutlineDash/> : <AiOutlineDown/> }
          </IconButton>
        </TableCell>
        <TableCell sx={{fontSize:18}} component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell sx={{fontSize:18,whiteSpace:'pre-wrap',width:200}} align="right">{row.address}</TableCell>
        <TableCell sx={{fontSize:18}} align="right">{row.date}</TableCell>
        {
          id === 'new' &&
          (
            <TableCell>
            <button 
              onClick={() =>approveHandle(row.id,row.email,row.name)}
              className={`${approveButton ? 'bg-green-400': 'bg-blue-400'} w-full p-1 rounded-lg text-white`}
            >
              {approveButton ? 'Approved' :'Approve'}
            </button>
            </TableCell>
          )
        }
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Details
              </Typography>
              <Table size="small" aria-label="purchases">
              
                <TableHead>
                  <TableRow>
                    <TableCell sx={{fontWeight:"bold",fontSize:'15px'}}>Name</TableCell>
                    <TableCell sx={{fontWeight:"bold",fontSize:'15px'}}>Gender</TableCell>
                    <TableCell sx={{fontWeight:"bold",fontSize:'15px'}}>Number</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow>
                  <TableCell component="th" scope="row">
                        {row.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {row.gender}
                    </TableCell>

                    <TableCell component="th" scope="row">
                        {row.phoneNumber}
                    </TableCell>
                  </TableRow>
                </TableBody>

                <TableHead>
                  <TableRow>
                    <TableCell sx={{fontWeight:"bold",fontSize:'15px'}}>Age</TableCell>
                    <TableCell sx={{fontWeight:"bold",fontSize:'15px'}}>Test</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {row.detail.map((detailRow) => (
                    <TableRow key={detailRow.date}>
                      <TableCell component="th" scope="row">
                        {detailRow.age}
                      </TableCell>
                      <TableCell sx={{display:'flex',flexDirection:'column', gap:2}}>{detailRow.test.map(item => (<span>{item.name}</span>))}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>

              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }


export default function BTbooking() {
    
  const [patientData,setPatientData] = useState([])

    
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

        const getData = async () =>{
          let temp = []
          const q = query(collRef,orderBy('created_at','desc'),where('approved','==',false))
          await getDocs(q)
          .then(snapshot =>{
            snapshot.docs.forEach(doc =>{
              const data = doc.data()
              const timestampInSeconds = data.created_at.seconds;
              const date = new Date(timestampInSeconds * 1000); // Convert seconds to milliseconds
              const formattedDate = date.toDateString();
              data.created_at = formattedDate
              temp.push({...data,id:doc.id})
            })
            
            setPatientData(temp)
          })
        }

    useEffect(() =>{
        try {
          getData()
        } catch (error) {
          console.log(error)
        }
        
    },[])

    const showApprovedData = () =>{
      try {
        let temp = []
        const q = query(collRef,orderBy('created_at','desc'),where('approved','==',true))
        const getData = async () =>{
          await getDocs(q)
          .then(snapshot =>{
            snapshot.docs.forEach(doc =>{
              const data = doc.data()
              const timestampInSeconds = data.created_at.seconds;
              const date = new Date(timestampInSeconds * 1000); // Convert seconds to milliseconds
              const formattedDate = date.toDateString();
              data.created_at = formattedDate
              temp.push({...data,id:doc.id})
            })
          })
          setPatientData(temp)
        }  
        getData()   
        
      } catch (error) {
        console.log(error)
      }
    }

    const approvedBooking = patientData.filter((data) =>{
      if(data.isApproved) return data
    })

    const allBooking = patientData.map(data =>{
      return createData(data.firstName,
        data.age,
        data.created_at,
        data.Address,
        data.test,
        data.gender,
        data.phoneNumber,
        data.id
        )
    })

    const bookingDisplay = patientData.map(data =>{
      return createData(
        data.username,
        data.age,
        data.created_at,
        data.email,
        data.streetAddress,
        data.test,
        data.gender,
        data.phoneNumber,
        data.id
        )
    })

    const approvedDisplay = approvedBooking.map(data =>{
      return createData(data.firstName,
        data.age,
        data.date,
        data.Address,
        data.test,
        data.gender,
        data.phoneNumber,
        data.id
        )
    })

    // const allBooking = [bookingDisplay + approvedDisplay]

  return (

    <div className='w-[100%] mt-10 relative'>
            
        <h1 className='mt-10 text-3xl px-2'>Blood Test Booking</h1>

        <Box sx={{backgroundColor:'transparent'}} style={{zIndex:0}}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="New Bookings" {...a11yProps(0)} onClick={getData} />
            <Tab label="Approved" {...a11yProps(1)} onClick={showApprovedData}/>
            <Tab label="Booking History" {...a11yProps(2)} />
            </Tabs>
        </Box>

        <CustomTabPanel value={value} index={0}>

                {/* new Booking */}
                <TableContainer  >
                        <Table aria-label="collapsible table">
                            <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell sx={{fontWeight:'bold',fontSize:20}}>Patient Name</TableCell>
                                <TableCell sx={{fontWeight:'bold',fontSize:20}} align="right">Address</TableCell>
                                <TableCell sx={{fontWeight:'bold',fontSize:20}} align="right">Application Date</TableCell>
                                {/* <TableCell sx={{fontWeight:'bold',fontSize:20}} align="right">Request</TableCell> */}
                                {/* <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {bookingDisplay.map((row) => (
                                <RowNew key={row.name} row={row} id='new'/>
                                ))}
                            </TableBody>
                        </Table>
                </TableContainer>
        </CustomTabPanel>

        <CustomTabPanel value={value} index={1}>
                <TableContainer >
                        <Table aria-label="collapsible table">
                            <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell sx={{fontWeight:'bold'}}>Patient Name</TableCell>
                                <TableCell sx={{fontWeight:'bold'}} align="right">Address</TableCell>
                                <TableCell sx={{fontWeight:'bold'}} align="right">Application Date</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {bookingDisplay.map((row) => (
                                <RowNew key={row.name} row={row} id='approved'/>
                                ))}
                            </TableBody>
                        </Table>
              </TableContainer>
        </CustomTabPanel>

        {/* <CustomTabPanel value={value} index={2}>
                <TableContainer>
                        <Table aria-label="collapsible table">
                            <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell sx={{fontWeight:'bold'}}>Patient Name</TableCell>
                                <TableCell sx={{fontWeight:'bold'}} align="right">Address</TableCell>
                                <TableCell sx={{fontWeight:'bold'}} align="right">Application Date</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {allBooking.map((row) => (
                                <RowNew key={row.name} row={row} id='approved'/>
                                ))}
                            </TableBody>
                        </Table>
              </TableContainer>
        </CustomTabPanel> */}
        </Box>
    </div>
  );
}
