import React,{useState} from 'react'
import {getAuth,signInWithEmailAndPassword} from 'firebase/auth'

import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { 
    MenuItem,
    FormGroup,
    FormControlLabel,
    Checkbox
     } from '@mui/material';
import { useAuthContext } from '../context/authProvider';

const auth = getAuth()



const LoginAdmin = ({setLoggedIn}) => {

    const {authState,setAuthState} = useAuthContext()

    const [errorMessage,setErrorMessage] = useState(false)

    const [loginData,setLoginData] = useState({
        email:'',
        password:''
    })
    const navigate = useNavigate()

    const changeHandler = (e) =>{
        const {id,value} = e.target
        setLoginData(prev =>({
            ...prev,
            [id]:value
        }))
    }

    const handleSubmit = async (e) =>{
        e.preventDefault()

        const {email,password} = loginData

        await signInWithEmailAndPassword(auth,email,password)
        .then(() => {
                setAuthState(true)
                setErrorMessage(false)
                localStorage.setItem('authStateLocal',true)
                // console.log('msg' + errorMessage)
                navigate('/admin/Dashboard')
                // return
            }
            )
            .catch((err) => setErrorMessage(true))
    }

  return (
    <div className='flex flex-col md:items-center p-5 bg-gray-50 h-screen'>

        <div>
            <h1 className='text-4xl font-extrabold tracking-tighter'>Prashanti Medical</h1>
        </div>
        <Box 
            component='form'
            sx={{display:'flex',flexDirection:'column',gap:4,marginTop:'50px'}}
            onSubmit={handleSubmit}
        >
            <h1 className='text-lg font-bold text-gray-600'>LogIn Admin</h1>
            <TextField
                id='email'
                label='Email'
                required={true}
                value={loginData.email}
                onChange={changeHandler}
            />

            <TextField
                id='password'
                label='Password'
                type='password'
                required={true}
                value={loginData.password}
                onChange={changeHandler}
            />

            {errorMessage ? (<span className='text-red-600'>Invalid email and password!</span>) : ''}

            <Button variant="contained" type="submit">
            Submit
            </Button>
        </Box>
    </div>
  )
}

export default LoginAdmin