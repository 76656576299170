import React from 'react'
import { NavLink } from 'react-router-dom'
import { MdDashboard } from "react-icons/md";
import { FaBriefcaseMedical } from "react-icons/fa";
import { FaUserDoctor } from "react-icons/fa6";
import { FaCreditCard } from "react-icons/fa6";
import { ImLab } from "react-icons/im";
import { RiBillFill } from "react-icons/ri";
import { RiFolderHistoryFill } from "react-icons/ri";



const sbLinks = [
  {
    title:'Home',
    links:[
      {
        link:'Dashboard',
        logo:<MdDashboard />
      }
    ]
  },
  {
    title:'Bookings',
    links:[
      {
        link:'BloodTestBookings',
        logo:<FaBriefcaseMedical />
      },
      {
        link:'Doctor Bookings',
        logo:<FaUserDoctor />
      },
    ]
  },

  {
    title:'Clinic',
    links:[
      {
        link:'Doctor Availability',
        logo:<FaCreditCard />
      },
      {
        link:'Lab Tests',
        logo:<ImLab />
      }
    ]
  },
  {
    title:'Store',
    links:[
      {
        link:'Billing',
        logo:<RiBillFill />
      },
      {
        link:'Case History',
        logo:<RiFolderHistoryFill />
      }
    ]
  },
]
const Sidebar = () => {
    
  const activeLink = 'bg-red-400 flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg  text-white  text-md m-2';
  const normalLink = 'flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-400 dark:text-gray-200 dark:hover:text-black hover:bg-gray-200 m-2';
    
    
  return (
    <div className=' bg-slate-900 w-full h-screen overflow-y-auto'>
        <div className='w-full h-32 flex items-center justify-center'>
        <div className="flex lg:flex-1 px-2">
        
              <span className="sr-only">Your Company</span>
              {/* <img
                className="h-8 w-auto"
                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                alt=""
              /> */}
              <div className='bg-indigo-600 p-2 rounded-lg'>
                <h1 className='font-extrabold text-lg text-white'>PD</h1>
              </div>
            
          </div>
        </div>
        <div>
          {
            sbLinks.map(item =>(
              <section  className='flex flex-col gap-1 px-2'>
                <h1 className='text-white text-xl tracking-tighter'>{item.title}</h1>
                {
                  item.links.map(item =>(
                    <NavLink 
                      to={`${item.link === 'Dashboard'? 'Dashboard': item.link}`}
                      key={item.link}
                      className={({isActive}) => isActive ? activeLink : normalLink}
                    >
                     {item.logo} {item.link}
                    </NavLink>
                  ))
                }
              </section>
              
            ))
          }
        </div>
    </div>
  )
}

export default Sidebar