import React, { useEffect, useState } from 'react'
import { BarChart } from '@mui/x-charts/BarChart';
import axios from 'axios'
import { PieChart } from '@mui/x-charts/PieChart';
import { doc, getDoc } from 'firebase/firestore';
import { database } from '../../firebase/firebaseConfig';
import { FaChartLine } from "react-icons/fa";
import { MdCurrencyRupee } from "react-icons/md";

const chartSetting = {
    xAxis: [
      {
        label: 'rainfall (mm)',
      },
    ],
    width: 500,
    height: 400,
};

const dataset =[
    {
        value:20,
        type:'TFT',
    },
    {
        value:30,
        type:'TFT1',
    },
    {
        value:70,
        type:'TFT2',
    },
    {
        value:60,
        type:'TFT3',
    },
    {
        value:10,
        type:'TFT4',
    },
    {
        value:12,
        type:'TFT5',
    },
    {
        value:25,
        type:'TFT6',
    },
    {
        value:78,
        type:'TFT7',
    },
    {
        value:55,
        type:'TFT8',
    },
    {
        value:20,
        type:'TFT9',
    },
]

const docDataset = [
    { id: 0, value: 10, label: 'series A' },
    { id: 1, value: 15, label: 'series B' },
    { id: 2, value: 20, label: 'series C' },
]

const valueFormatter = (value) => `${value}mm`;

const StatCard = ({stat,text}) =>(
    <div className='w-80 h-80 bg-blue-400/50 rounded-lg shadow-2xl flex flex-col gap-5 items-center justify-center'>
        
            <h1 className='text-6xl font-extrabold text-slate-800 flex gap-x-1 items-end'><MdCurrencyRupee />{ stat } <span className='text-sm'>INR</span></h1>
            <h1 className='text-2xl font-bold text-slate-800 flex gap-x-1 items-center'>{text} <FaChartLine /></h1>
        
    </div>
)

const currentDate = new Date()
const options = { year: 'numeric', month: 'long' };
const formattedDate2 = currentDate.toLocaleDateString(undefined, options);
const formattedDate = currentDate.toDateString();
const options2 = {year:'numeric'}
const year = currentDate.toLocaleDateString(undefined,options2)

            

const Panel = () => {
    const [notification,setNotification] = useState({})
    const [data,setData] = useState(null)
    const [yearData,setYearData] = useState(null)

    
    useEffect(() =>{
        const fetchData = async() =>{
           
            const monthlyRef = doc(database,'Revenue',formattedDate2)
            const yearRef = doc(database,'Revenue','Annual')
            await getDoc(monthlyRef)
            .then(snapshot =>{
                const temp = snapshot.data()
                setData(temp)
            })
            .catch(() =>{
                setData(null)
            })

            await getDoc(yearRef)
            .then((snapshot) =>{
                setYearData(snapshot.data())
                console.log(snapshot.data())
            })
        }

        fetchData()
    },[])

    let dailyRevenue = 0
    let monthRevenue = 0
    let yearRevenue = 0
    data && data.data.map(item =>{
        if(item.date === formattedDate){
            dailyRevenue += item.price
        }
        monthRevenue += item.price
    })
    yearData && yearData[year].map(item =>{
        yearRevenue += item
    })


    
    


  return (
    <div className={`px-10`}>
                    <div className='mt-10'>
                        <h1 className='text-5xl font-semibold'>Prashanti Diagnostic Center</h1>
                        <h2  className='text-2xl'>Welcome, Admin</h2>
                    </div>

                    <section className='h-80 flex gap-10 mt-10'>
                        <StatCard 
                        stat={dailyRevenue}
                        text="Today's Income"
                        />
                        <StatCard 
                        stat={monthRevenue}
                        text='This Month Revenue'
                        />
                        <StatCard 
                        stat={yearRevenue}
                        text='Annual Income'
                        />
                    </section>

                    <div className='w-full flex gap-2 mt-10'>
                        <section className='h-[34rem] w-3/4 bg-white rounded-lg shadow-2xl'>
                            <h1 className='py-5 px-10 text-4xl'>Test Booking Data</h1>
                            <BarChart
                            dataset={dataset}
                            yAxis={[{ scaleType: 'band', dataKey: 'type' }]}
                            series={[{ dataKey: 'value', label: 'Seoul rainfall', valueFormatter }]}
                            layout="horizontal"
                            {...chartSetting}
                            width={1000}
                            height={450}
                            />
                        </section>

                        <section className='h-[34rem] w-1/4 bg-white rounded-lg shadow-2xl'>
                            <h1>highest booking</h1>
                        </section>
                    </div>

                    <div className='w-full flex gap-2 mt-10'>
                        <section className='h-[28rem] w-2/4 bg-white rounded-lg shadow-2xl'>
                        <h1  className='py-5 px-10 text-4xl'>Doctor Booking Data</h1>
                        <PieChart
                        series={[
                            {
                                data:docDataset,
                                innerRadius: 50,
                                outerRadius: 150,
                                paddingAngle: 5,
                                cornerRadius: 5,
                                startAngle: -90,
                                endAngle: 180,
                                cx: 190,
                                cy: 190,
                            },
                        ]}
                        width={500}
                        height={350}
                        // sx={{backgroundColor:'red',display:'flex',alignContent:"center"}}
                        />
                        </section>

                        <section className='h-[28rem] w-2/4 bg-white rounded-lg shadow-2xl'>
                            <h1>Doctor Booking Graph</h1>
                        </section>
                    </div>
                </div>
  )
}

export default Panel