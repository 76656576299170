import React, { useEffect, useState } from 'react'
import { database } from '../../firebase/firebaseConfig'
import { getDocs,updateDoc,deleteField, collection, doc, arrayUnion,arrayRemove, onSnapshot } from 'firebase/firestore'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {RiDeleteBin6Line} from 'react-icons/ri'
import { TextField } from '@mui/material';
import {IoIosAdd} from 'react-icons/io'
import {GrFormClose} from 'react-icons/gr'

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const templateTest = {
    name:'',
    price:0
}

const AddButton = ({id,toggleAdd,changeHandlerAdd,setToggleAdd,newTest,addTest}) =>{
    return(
        <div className='flex gap-5'>
            <button onClick={() =>setToggleAdd(prev => !prev)}>
                {
                    toggleAdd? <span className='flex gap-1 items-center'><GrFormClose/>Close</span>: <span className='flex gap-1 items-center'><IoIosAdd/>Add Test</span>
                }

            </button>
            
            {
                toggleAdd && (
                    <secion className={`flex gap-5`}>
                        <TextField
                            id='name'
                            label='Test Name'
                            value={newTest.name}
                            onChange={changeHandlerAdd}
                        />

                        <TextField
                            id='price'
                            label='Test Price'
                            value={newTest.price}
                            type='number'
                            onChange={changeHandlerAdd}
                        />
                        <button onClick={() => addTest(id)}>Save</button>
                    </secion>
                )
            }

        </div>
    )
}

const LabSettings = () => {

    const [data,setData] = useState(null)
    const [value, setValue] = React.useState(0);
    const [newTest,setTest] = useState(templateTest)
    const [toggleAdd,setToggleAdd] = useState(false)

    const changeHandlerAdd = (e) =>{
        const {id,value} = e.target
        setTest(prev =>({
            ...prev,
            [id]:value
        }))
    }

    useEffect(()=>{
        
        const getData = async() =>{
            const collRef = collection(database,'LabTest')
            try{
                let temp = []
                await getDocs(collRef)
                .then((snapShot) =>{
                    snapShot.docs.forEach(doc =>{
                        temp[doc.id] = doc.data()
                        // temp.push(doc.data())
                    })
                    setData(temp)
                    // console.log(data.lab.tests)
                })
                .catch((err) =>{
                    console.log(err)
                    // setData(null)
                })

            }
            catch(err){
                // setData(null)
                console.log(err)
            }
        }
        getData()
    },[])
    // const collRef = collection(database,'LabTest')
    // let temp=[]
    //  onSnapshot(collRef,(snapShot) =>{
    //     snapShot.docs.forEach(doc =>{
    //         temp[doc.id] = doc.data()
    //         // temp.push(doc.data())
    //     })
    //     console.log(temp)
    //     setData(temp)
    // })

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
   
    const addTest = (id) =>{
        const testRef = doc(database,'LabTest',`${id}`)
        try{
            updateDoc(testRef,{
                tests:arrayUnion(newTest)
            })
            setTest(templateTest)
        }
        catch(err){
            console.log(err)
        }
    }

    const tableDataChange = (name,id,value,oldValue) =>{

        const slicedData = data[id].tests.map(rowData =>{
            if(rowData.name === name){
                return {...rowData,price:value}
            }
            return rowData
        })


        setData(prev =>({
            ...prev,
            [id]:{...prev[id],tests:slicedData}
        }))

        try{
            const testRef = doc(database,'LabTest','lab')
            const oldItem = {
                name:name,
                price:oldValue
            }
    
            updateDoc(testRef,{
                tests:arrayRemove(oldItem)
            })
            .then(() =>{
                updateDoc(testRef,{
                    tests:arrayUnion(...slicedData)
                })
            })
        }
        catch(err){
            console.log(err)
        }        
    }

    const deleteTest = (name,price,id) =>{
        const testRef = doc(database,'LabTest',`${id}`)
        updateDoc(testRef,{
            tests:arrayRemove({
                name:name,
                price:price
            })
        })
    }

    const RowData = ({item,index,id}) =>(
        <tr className='text-center'>
            <td className='p-2'>{index + 1}</td>
            <td className='p-2'>{item.name}</td>
            <td  className='p-2'
            suppressContentEditableWarning
            contentEditable={true} 
            onBlur={(e) =>tableDataChange(item.name,id,parseFloat(e.target.innerText),item.price)}
            > 
                {item.price}
            </td>
            <td className='p-2'>
                <button onClick={() =>deleteTest(item.name,item.price,id)}><RiDeleteBin6Line/></button>
            </td>
        </tr>

    )

  



  return (
    <div>
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="LAB" {...a11yProps(0)} />
                    <Tab label="USG" {...a11yProps(1)} />
                    <Tab label="ECG" {...a11yProps(2)} />
                    <Tab label="DigitalXray" {...a11yProps(3)} />
                    <Tab label="XRay" {...a11yProps(4)} />
                    <Tab label="CT Scan" {...a11yProps(5)} />
                    <Tab label="Outsource" {...a11yProps(6)} />
                </Tabs>
            </Box>

            {/* lab */}
            <CustomTabPanel value={value} index={0}>

                   <AddButton 
                        id='lab'
                        toggleAdd={toggleAdd}
                        newTest={newTest}
                        changeHandlerAdd={changeHandlerAdd}
                        addTest={addTest}
                        setToggleAdd={setToggleAdd}
                    />

                   <div className=''>
                        <table className='w-full h-full  mt-10  bg-green-400'>
                            <thead>
                            <tr>
                            <th>Sl.no</th>
                                <th>Test Name</th>
                                <th>Price</th>
                                <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                                {
                                    data !== null && data.lab.tests.map((item,index) =>(
                                        <RowData item={item} index={index} id='lab'/>
                                    ))
                                }
                            </tbody>
                        </table>
                   </div>
            </CustomTabPanel>

            {/* USG */}
            <CustomTabPanel value={value} index={1}>
                    
                    <AddButton 
                        id='usg' 
                        toggleAdd={toggleAdd}
                        newTest={newTest}
                        changeHandlerAdd={changeHandlerAdd}
                        addTest={addTest}
                        setToggleAdd={setToggleAdd}
                    />

                   <div className='h-20'>
                        <table className='w-full h-full  mt-10  bg-green-400'>
                            <thead>
                            <tr>
                            <th>Sl.no</th>
                                <th>Test Name</th>
                                <th>Price</th>
                                <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                                {
                                    // data === null ? '':
                                    data!==null && data.usg.tests.map((item,index) =>(
                                        <RowData item={item} index={index} id='usg'/>
                                    ))
                                }
                            </tbody>
                        </table>
                   </div>
            </CustomTabPanel>

            {/* ecg */}
            <CustomTabPanel value={value} index={2}>
                    
                <AddButton 
                    id='ecg'
                    toggleAdd={toggleAdd}
                    newTest={newTest}
                    changeHandlerAdd={changeHandlerAdd}
                    addTest={addTest}
                    setToggleAdd={setToggleAdd}
                />

                   <div className='h-20'>
                        <table className='w-full h-full  mt-10  bg-green-400'>
                            <thead>
                            <tr>
                            <th>Sl.no</th>
                                <th>Test Name</th>
                                <th>Price</th>
                                <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                                {
                                    data!==null && data.ecg.tests.map((item,index) =>(
                                        <RowData item={item} index={index} id='ecg'/>
                                    ))
                                }
                            </tbody>
                        </table>
                   </div>
            </CustomTabPanel>

            {/* digitalXray */}

            <CustomTabPanel value={value} index={3}>

                    <AddButton 
                        id='digitalXray'
                        toggleAdd={toggleAdd}
                        newTest={newTest}
                        changeHandlerAdd={changeHandlerAdd}
                        addTest={addTest}
                        setToggleAdd={setToggleAdd}
                    />

                   <div className='h-20'>
                        <table className='w-full h-full  mt-10  bg-green-400'>
                            <thead>
                            <tr>
                            <th>Sl.no</th>
                                <th>Test Name</th>
                                <th>Price</th>
                                <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                                {
                                    data!==null && data.digitalXray.tests.map((item,index) =>(
                                        <RowData item={item} index={index} id='digitalXray'/>
                                    ))
                                }
                            </tbody>
                        </table>
                   </div>
            </CustomTabPanel>

            {/* xray */}

            <CustomTabPanel value={value} index={4}>
            
                    <AddButton 
                        id='xray'
                        toggleAdd={toggleAdd}
                        newTest={newTest}
                        changeHandlerAdd={changeHandlerAdd}
                        addTest={addTest}
                        setToggleAdd={setToggleAdd}
                    />

                   <div className='h-20'>
                        <table className='w-full h-full  mt-10  bg-green-400'>
                            <thead>
                            <tr>
                            <th>Sl.no</th>
                                <th>Test Name</th>
                                <th>Price</th>
                                <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                                {
                                    data!==null && data.xray.tests.map((item,index) =>(
                                        <RowData item={item} index={index} id='xray'/>
                                    ))
                                }
                            </tbody>
                        </table>
                   </div>
            </CustomTabPanel>

            {/* ctscan */}

            <CustomTabPanel value={value} index={5}>
                    
                    <AddButton 
                        id='ctscan'
                        toggleAdd={toggleAdd}
                        newTest={newTest}
                        changeHandlerAdd={changeHandlerAdd}
                        addTest={addTest}
                        setToggleAdd={setToggleAdd}
                    />

                   <div className='h-20'>
                        <table className='w-full h-full  mt-10  bg-green-400'>
                            <thead>
                            <tr>
                            <th>Sl.no</th>
                                <th>Test Name</th>
                                <th>Price</th>
                                <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                                {
                                    data!==null && data.ctscan.tests.map((item,index) =>(
                                        <RowData item={item} index={index} id='ctscan'/>
                                    ))
                                }
                            </tbody>
                        </table>
                   </div>
            </CustomTabPanel>

            {/* outsource */}

            <CustomTabPanel value={value} index={6}>
                    <AddButton 
                        id='outsource'
                        toggleAdd={toggleAdd}
                        newTest={newTest}
                        changeHandlerAdd={changeHandlerAdd}
                        addTest={addTest}
                        setToggleAdd={setToggleAdd}
                    />

                   <div className='h-20'>
                        <table className='w-full h-full  mt-10  bg-green-400'>
                            <thead>
                            <tr>
                            <th>Sl.no</th>
                                <th>Test Name</th>
                                <th>Price</th>
                                <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                                {
                                    data!==null && data.outsource.tests.map((item,index) =>(
                                        <RowData item={item} index={index} id='outsource'/>
                                    ))
                                }
                            </tbody>
                        </table>
                   </div>
            </CustomTabPanel>
        </Box>
    </div>
  )
}

export default LabSettings