import React, { useState } from 'react'
import Navbar from '../components/Navbar'
import { getAuth,signInWithEmailAndPassword } from 'firebase/auth'
import { createUserWithEmailAndPassword,sendEmailVerification} from 'firebase/auth'
import UserDataForm from '../components/userRegistration/UserDataForm'
import { doc, setDoc} from 'firebase/firestore'
import { database } from '../firebase/firebaseConfig'
import { useNavigate } from 'react-router-dom'
import Footer from '../components/Footer'



const templateUser = {
    username:'',
    email:'',
    password:'',
    repassword:''
}

const templateUserData = {
    phoneNumber:'',
    streetAddress:'',
    age:0,
    gender:'',
}

const Signup = () => {

    const [user,setUser] = useState(templateUser) 
    const [userData,setUserData] = useState(templateUserData)
    const [step,setStep] = useState(0)
    const [error,setError] = useState(null)
    const [passErr,setPassErr] = useState(false)

    const auth = getAuth()
    const navigate = useNavigate()


    const signup = () => {
        const {email,password,repassword} = user
        
        try {
            if(password === repassword){
                createUserWithEmailAndPassword(auth,email, password)
                .then((userCredential) => {
                    sendEmailVerification(auth.currentUser)
                    setStep(1)
                    const localstorage = window.localStorage
                    localstorage.setItem('userIn',true)
                    const userid = userCredential.user.uid
                    localstorage.setItem('userId',userid)
                })
                .catch((err) =>{
                    console.log(err)
                    setError('Email already in use!')
                })
            }
            else{
                setPassErr(true)
            }
            
        } catch (error) {
            console.log(error)
        }
        
    }

    const changeHandler = (e) =>{
        const {id,value} = e.target
        if(passErr === true) setPassErr(false)
        setUser(prev => ({
            ...prev,
            [id]:value
        }))
    }

    const checkEmail = () =>{
        const {email,password} = user
        try {
            signInWithEmailAndPassword(auth,email,password)
            .then((userCred) =>{
                if(userCred.user.emailVerified){
                    setStep(2)
                }
                else{
                    auth.currentUser.delete()
                    .then(() => {
                        const localstorage = window.localStorage
                        localstorage.removeItem('userIn')
                        setError('Email Verification failed!')
                        setStep(0)
                    })
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    const handleSubmit = async(e) =>{
        e.preventDefault()
        const userid = auth.currentUser.uid
        const ref = doc(database,'users',userid)
        const username = user.username
        const email = user.email

        try{
            await setDoc(ref,{username,...userData,email})
            .then(() => navigate('/'))
        }
        catch(err){
            console.log(err)
        }
    }

  return (
    <div>
        <div>
            <Navbar/>
        </div>

        <div className='flex'>
        <svg
            className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
              >
                <path d="M100 200V.5M.5 .5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
              <path
                d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect width="100%" height="100%" strokeWidth={0} fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" />
          </svg>

            {
                step === 0 ?(
                    <section className='w-full min-h-[75vh] flex items-center justify-center'>
                        <div className='w-[90%] h-[80%] md:w-[80%] md:mt-20 flex items-center justify-center'>
                                <div className=' w-full md:w-1/2 flex flex-col gap-5 md:p-0'>
                                        <h1 className='my-6 text-2xl font-bold leading-9 tracking-tight text-gray-900'>Unlock Health Insights: Create Your Prashanti Medical Diagnostics Account Today</h1>
                                        
                                        {error !== null ? <h1 className='text-red-400'>{error}</h1>:''}
                                        
                                        <div className='w-full'>
                                            <label for="username" className="block font-medium leading-6 text-gray-900">Username</label>
                                            <div class="mt-2">
                                                <input onChange={changeHandler} type="username" name="username" id="username" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 p-2" placeholder="user123"/>
                                            </div>
                                        </div>

                                        <div className='w-full'>
                                            <label for="email" className="block font-medium leading-6 text-gray-900">Email</label>
                                            <div class="mt-2">
                                                <input onChange={changeHandler} type="email" name="email" id="email" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 p-2" placeholder="you@example.com"/>
                                            </div>
                                        </div>

                                        <div className='w-full'>
                                            <label for="password" className="block font-medium leading-6 text-gray-900">New Password</label>
                                            <div class="mt-2">
                                                <input onChange={changeHandler} type="password" name="password" id="password" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 p-2" placeholder="new password"/>
                                            </div>
                                            <div class="mt-2">
                                                <input onChange={changeHandler} type="password" name="repassword" id="repassword" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 p-2" placeholder="Re-enter password"/>
                                            </div>
                                        </div>
                                        {passErr? <span className='text-sm text-red-500'>Password's do not match!</span>:''}
                                        <button onClick={signup} className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Signup</button>
                                    </div>
                        </div>
                    </section>
                )
                :
                step === 1 ?(
                    <section className='min-h-[60vh] flex flex-col gap-10 items-center justify-center w-full'>
                        <section>
                            <h1 className='text-lg font-medium text-slate-800'>Please click on the link sent to your Email</h1>
                            <h1 className='text-gray-500'>{user.email}</h1>
                        </section>
                        <section className='flex flex-col gap-1'>
                            <span className='text-sm text-gray-500'>Once you have verified click on continue </span>
                            <button onClick={checkEmail} className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Continue</button>
                        </section>
                        <span className='text-red-500 text-sm'>***Do not continue click without verifying your email</span>
                    </section>
                )
                :
                step === 2 ? (
                    <UserDataForm userData={userData} setUserData={setUserData} handleSubmit={handleSubmit}/>
                ) : ''
            }
        </div>
        <Footer/>
    </div>
  )
}

export default Signup