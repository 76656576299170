import React from 'react'
import { database } from '../firebase/firebaseConfig'
import {  Outlet, Route, Routes} from 'react-router-dom'

import Sidebar from './components/Sidebar'
import Navbar from './components/Navbar'
import Panel from './pages/Panel'
import BTbooking from './pages/BTbooking'
import DocBooking from './pages/DocBooking'
import DocTiming from './pages/DocTiming'
import Billing from './pages/Billing'
import CaseHistory from './pages/CaseHistory'
import LabSettings from './pages/LabSettings'



const Dashboard = () => {
    
  


    
  return (
    
        <div className=''>

            <div>
                <div className={`w-80 fixed z-20`}>
                    <Sidebar/>
                </div>
            </div>

            <div className='flex flex-col'>


                <div className='fixed w-[100%] z-10'>
                    <Navbar/>
                </div>
                
                {/* <Routes>
                    <Route path='/*'>
                        <Route index element={<Panel/>}/>
                        <Route path='BloodTestBookings' element={<BTbooking/>}/>
                        <Route path='DoctorBooking' element={<DocBooking/>}/>
                        <Route path = 'EditTiming' element={<DocTiming/>}/>
                    </Route>
                    <Route path='test' element={<h1>hello</h1>}/>
                </Routes> */}

                <div className='ml-80 mt-28 min-h-screen'>
                <Routes>
                    <Route path='/Dashboard' element={<Panel />} />
                    <Route path='BloodTestBookings' element={<BTbooking />} />
                    <Route path='Doctor Bookings' element={<DocBooking />} />
                    <Route path='EditTiming' element={<DocTiming />} />
                    <Route path='Billing' element={<Billing />} />
                    <Route path='Case History' element={<CaseHistory/>}/>
                    <Route path='Lab Tests' element={<LabSettings/>}/>
                    <Route path='Doctor Availability' element={<DocTiming/>}/>
                </Routes>
                </div>
            </div>
            <Outlet/>
        </div>
  )
}

export default Dashboard