import React from 'react'
import Footer from '../Footer'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

function loadScript(src) {
	return new Promise((resolve) => {
		const script = document.createElement('script')
		script.src = src
		script.onload = () => {
			resolve(true)
		}
		script.onerror = () => {
			resolve(false)
		}
		document.body.appendChild(script)
	})
}

const __DEV__ = document.domain === 'localhost'

const TestBilling = ({estimate,userData,submitBooking}) => {
    let total=0
    let grandTotal = 50
    estimate.map(item =>{
        total += item.price
    })
    grandTotal += total 

    const navigate = useNavigate()

    async function displayRazorpay() {
      const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')
  
      if (!res) {
        alert('Razorpay SDK failed to load. Are you online?')
        return
      }

      const data = await axios.post('http://localhost:5001/razorpay',{grandTotal})
      console.log(data.data.amount)
      
      const options = {
        key: __DEV__ ? 'rzp_test_93Bs1vjou27Wjp' : 'PRODUCTION_KEY',
        currency: data.currency,
        amount: String(data.data.amount),
        order_id: data.id,
        name: 'Prahanti Diagnostics',
        description: 'Thank you for nothing. Please give us some money',
        // image: 'http://localhost:1337/logo.svg',
        handler: function (response) {
          // alert(response.razorpay_payment_id)
          // alert(response.razorpay_order_id)
          // alert(response.razorpay_signature)
          submitBooking()
          navigate('/')
        },
        prefill: {
          name:userData.username,
          email: userData.email,
          phone_number: userData.phoneNumber
        }
      }
      const paymentObject = new window.Razorpay(options)
      paymentObject.open()
    }

  return (
    <main className='flex flex-col items-center'>
       


       <div className="bg-white">
            <div className="mx-auto max-w-3xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
              <div className="max-w-xl">
                <h1 className="text-base font-medium text-indigo-600">Thank you!</h1>
                <p className="mt-2 text-4xl font-bold tracking-tight sm:text-5xl">Checkout Page</p>
                <p className="mt-2 text-base text-gray-500">Check your test details and wait for us to arrive at your doorsteps</p>
              </div>

              <div className="mt-10 border-t border-gray-200">
                <h2 className="sr-only">Your order</h2>

                <h3 className="sr-only">Items</h3>
                {estimate.map((product) => (
                  <div key={product.id} className="flex space-x-6 border-b border-gray-200 py-10">
                    <div className="flex flex-auto flex-col">
                      <div className="mt-6 flex flex-1 items-end">
                        <dl className="flex space-x-4 divide-x divide-gray-200 text-sm sm:space-x-6">
                          <div className="flex pl-4 sm:pl-6">
                            <dt className="font-medium text-gray-900">Name:</dt>
                            <dd className="ml-2 text-gray-700">{product.name}</dd>
                          </div>
                          <div className="flex pl-4 sm:pl-6">
                            <dt className="font-medium text-gray-900">Price</dt>
                            <dd className="ml-2 text-gray-700">{product.price}</dd>
                          </div>
                        </dl>
                      </div>
                    </div>
                  </div>
                ))}

                <div className="sm:ml-40 sm:pl-6">
                  <h3 className="sr-only">Your information</h3>

                  <h4 className="sr-only">Addresses</h4>
                  <dl className="grid grid-cols-2 gap-x-6 py-10 text-sm">
                    <div>
                      <dt className="font-medium text-gray-900">Address</dt>
                      <dd className="mt-2 text-gray-700">
                        <address className="not-italic">
                          <span>{userData.streetAddress}</span>
                        </address>
                      </dd>
                    </div>
                    <div>
                      <dt className="font-medium text-gray-900">Billing address</dt>
                      <dd className="mt-2 text-gray-700">
                        <address className="not-italic">
                          <span>{userData.streetAddress}</span>
                        </address>
                      </dd>
                    </div>
                  </dl>

                  <h4 className="sr-only">Payment</h4>
                  <dl className="grid grid-cols-2 gap-x-6 border-t border-gray-200 py-10 text-sm">
                    <div>
                      <dt className="font-medium text-gray-900">Payment method</dt>
                      <dd className="mt-2 text-gray-700">
                        <p>Google Pay</p>
                        <p>UPI</p>
                      </dd>
                    </div>
                  </dl>

                  <h3 className="sr-only">Summary</h3>

                  <dl className="space-y-6 border-t border-gray-200 pt-10 text-sm">
                    <div className="flex justify-between">
                      <dt className="font-medium text-gray-900">Subtotal</dt>
                      <dd className="text-gray-700">Rs. {total}</dd>
                    </div>
                    <div className="flex justify-between">
                      <dt className="flex font-medium text-gray-900">
                        Discount
                        <span className="ml-2 rounded-full bg-gray-200 px-2 py-0.5 text-xs text-gray-600">STUDENT50</span>
                      </dt>
                      <dd className="text-gray-700">-Rs. 0</dd>
                    </div>
                    <div className="flex justify-between">
                      <dt className="font-medium text-gray-900">Booking Charge</dt>
                      <dd className="text-gray-700">Rs. 50</dd>
                    </div>
                    <div className="flex justify-between">
                      <dt className="font-medium text-gray-900">Total</dt>
                      <dd className="text-gray-900">{total + 50}</dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          </div>
            
            
          <button onClick={displayRazorpay} className='hover:bg-black/90 shadow-lg bg-black text-white w-1/2 h-12'>PAY Rs.{grandTotal}</button>
          
          <Footer/>
      </main>
  )
}

export default TestBilling