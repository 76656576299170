import { Outlet,Navigate } from "react-router-dom";
import Cookies from "universal-cookie";
const UserControl = () => {

    // const cookie = new Cookies()
    // const state = cookie.get('tokenUser')
    // const tr = false
    // console.log(state) 
    const state = window.localStorage.getItem('userIn')
    console.log(state)

    return(
      state !== null ? <Outlet/> : <Navigate to='/Userlogin' replace/>
    )
  
};
export default UserControl;