// import React from 'react';
// import { useNavigate } from 'react-router-dom';
// import NavBar from '../components/Navbar';

// import {MdLocalHospital,MdBloodtype} from 'react-icons/md'
// import {BsFillSearchHeartFill} from 'react-icons/bs'
// import {BiSolidDonateBlood} from 'react-icons/bi'
// import {GiHospitalCross} from 'react-icons/gi'
// import Footer from '../components/Footer';
// // import {MdBloodtype} from 'react-icons/md'



// function LandingPage() {

//   const navigate = useNavigate()

//   const handleClick = (link) =>{
//     navigate(`/${link}`)
//   }

//   const primary = 'bg-emerald-400'
//   const secondary = 'border-2 border-emerald-400'
  
//   return (
//     <div className='relative bg-gradient-to-b from-cyan-50 via-gray-100 to-sky-200'>
//        <div className='absolute flex w-3/4  h-full right-0 overflow-hidden z-0'>
//         <div className="w-2/5 h-full 
//         border-l-[50rem] border-l-transparent
//         border-t-[57rem] border-t-green-300
//         border-r-[0px] border-r-transparent">
//         </div>
//         <div className='w-3/5 h-full  bg-green-300  right-0'></div>
//        </div>
//       <NavBar />

    

//       <div className='relative px-2 w-full h-screen flex items-center'>
          
//           <div className='lg:w-1/2 lg:h-full'>
//             <section className='w-full pl-16'>
//                 <h1 className='mb-10 text-6xl lg:hidden text-slate-800 drop-shadow-lg'>Prashanti Diagnostics Center</h1>
//                 <h1 className='lg:hidden flex text-3xl lg:text-4xl text-slate-800 drop-shadow-lg'>Revolutionizing Healthcare<span className='hidden'><BiSolidDonateBlood/></span></h1>
                
//                 <p className='lg:hidden text-slate-700 mt-2 lg:text-xl text-sm '>
//                   With our convenient blood test collection at home, you can skip the hassle of waiting rooms and get your tests done in the comfort of your own space.
//                 </p>
                
//                 <div className='hidden h-1/2 md:flex'>
//                   <img src={require('../images/doc.jpg')} className='mix-blend-multiply aspect-square h-[30%] w-2/3'/>
//                 </div>
                
                
//                 <div className='hidden lg:flex w-full mt-10 text-lg'>
//                   <section className='w-1/3 h-full text-3xl font-semibold'>Revolutionizing Healthcare.</section>
//                   <section className='w-2/3 h-full pr-4'>
//                     <p>we believe that true wellness encompasses not only the absence of illness but also the presence of the highest peace within.Our evolution from a simple pharmacy to the esteemed establishment we stand as today has been marked by relentless dedication, tireless efforts, and the unwavering support of countless individuals.</p>
//                   </section>
//                 </div>


//             </section>

//             <h1 className='lg:hidden text-4xl tracking-tighter font-semibold text-left md:text-center w-full mt-10 py-2'>Booking</h1>

//             <section className='lg:hidden flex flex-col gap-5 p-2 w-full py-10'>
//                 <CustomButton 
//                   cardName='Blood Test'
//                   color={primary}
//                   link='BloodTest'
//                   handleClick={handleClick}

//                 />
//                 <CustomButton 
//                   cardName='Doctor Appointment'
//                   color={secondary}
//                   link='DoctorAppointment'
//                   handleClick={handleClick}
//                 />
//             </section>
//           </div>

//           <div className='hidden lg:block w-1/2'>
//             <h1 className='mb-10 text-6xl lg:text-8xl text-slate-800 drop-shadow-lg'>Prashanti Diagnostics Center</h1>
//             <section className='flex gap-5 p-2 w-2/3 lg:w-full py-10'>
//                 <CustomButton 
//                   cardName='Blood Test'
//                   color={primary}
//                   link='BloodTest'
//                   handleClick={handleClick}
//                 />
//                 <CustomButton 
//                   cardName='Doctor Appointment'
//                   color={secondary}
//                   link='DoctorAppointment'
//                   handleClick={handleClick}
//                 />
//             </section>
//           </div>
//       </div>

//       <Footer/>

//     </div>
//   );
// }

// export default LandingPage;

/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { useState } from 'react'

import {
  ArrowPathIcon,
  ChevronRightIcon,
  CloudArrowUpIcon,
  LockClosedIcon,
  ServerIcon,
} from '@heroicons/react/20/solid'
import { useNavigate } from 'react-router-dom'
import Navbar from '../components/Navbar'
import axios from 'axios'
import Footer from '../components/Footer'


const primaryFeatures = [
  {
    name: 'Push to deploy.',
    description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit aute id magna.',
    icon: CloudArrowUpIcon,
  },
  {
    name: 'SSL certificates.',
    description: 'Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.',
    icon: LockClosedIcon,
  },
  {
    name: 'Database backups.',
    description: 'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus.',
    icon: ServerIcon,
  },
]
const secondaryFeatures = [
  {
    name: 'Home Medical Testing',
    description:
      'Our Online Diagnostic Center allows you to conveniently order a wide range of medical tests from the comfort of your home. From routine blood work to specialized diagnostic tests, you can collect your samples at your convenience.',
    href: '#',
    icon: CloudArrowUpIcon,
  },
  {
    name: 'Online Appointment Booking',
    description:
      'Booking a medical appointment has never been easier. With our user-friendly online platform, you can schedule appointments with your preferred healthcare professionals at your convenience. Browse available time slots, choose a specialist, and confirm your appointment with just a few clicks.',
    href: '#',
    icon: LockClosedIcon,
  },
  {
    name: 'Best Consultation',
    description:
      'Experience top-notch healthcare consultation through our Online Diagnostic Center. Our platform connects you with a network of highly skilled and experienced healthcare providers, offering you the best possible medical guidance. ',
    href: '#',
    icon: ArrowPathIcon,
  },
]
const featuredTestimonial = {
  body: 'Integer id nunc sit semper purus. Bibendum at lacus ut arcu blandit montes vitae auctor libero. Hac condimentum dignissim nibh vulputate ut nunc. Amet nibh orci mi venenatis blandit vel et proin. Non hendrerit in vel ac diam.',
  author: {
    name: 'Brenna Goyette',
    handle: 'brennagoyette',
    imageUrl:
      'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=1024&h=1024&q=80',
    logoUrl: 'https://tailwindui.com/img/logos/savvycal-logo-gray-900.svg',
  },
}
const testimonials = [
  [
    [
      {
        body: 'Laborum quis quam. Dolorum et ut quod quia. Voluptas numquam delectus nihil. Aut enim doloremque et ipsam.',
        author: {
          name: 'Leslie Alexander',
          handle: 'lesliealexander',
          imageUrl:
            'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
      },
      // More testimonials...
    ],
    [
      {
        body: 'Aut reprehenderit voluptatem eum asperiores beatae id. Iure molestiae ipsam ut officia rem nulla blanditiis.',
        author: {
          name: 'Lindsay Walton',
          handle: 'lindsaywalton',
          imageUrl:
            'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
      },
      // More testimonials...
    ],
  ],
  [
    [
      {
        body: 'Voluptas quos itaque ipsam in voluptatem est. Iste eos blanditiis repudiandae. Earum deserunt enim molestiae ipsum perferendis recusandae saepe corrupti.',
        author: {
          name: 'Tom Cook',
          handle: 'tomcook',
          imageUrl:
            'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
      },
      // More testimonials...
    ],
    [
      {
        body: 'Molestias ea earum quos nostrum doloremque sed. Quaerat quasi aut velit incidunt excepturi rerum voluptatem minus harum.',
        author: {
          name: 'Leonard Krasner',
          handle: 'leonardkrasner',
          imageUrl:
            'https://images.unsplash.com/photo-1519345182560-3f2917c472ef?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
      },
      // More testimonials...
    ],
  ],
]


const CustomButton = ({cardName,link,handleClick,color}) =>(
  <button onClick={() => handleClick(link)}
          className={`${color} shadow-2xl`}
  >
    <h1>{cardName}</h1>
  </button>
)


export default function LandingPage() {
  
    const navigate = useNavigate()

    const primary = 'rounded-md px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 bg-indigo-600'
    const secondary = 'rounded-md px-3.5 py-2.5 text-sm font-semibold shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 border-indigo-600 border-2 text-indigo-600'

  const handleClick = (link) =>{
    navigate(`/${link}`)
  }

  return (
    <div className="bg-white">
      
      <Navbar/>
      

      <main>
        {/* Hero section */}
        <div className="relative isolate pt-14">
      
          <svg
            className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
              >
                <path d="M100 200V.5M.5 .5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
              <path
                d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect width="100%" height="100%" strokeWidth={0} fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" />
          </svg>
          <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-40">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
              <div className="flex">
                <div className="relative flex items-center gap-x-4 rounded-full px-4 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                  <span className="font-semibold text-indigo-600">We’re here</span>
                  <span className="h-4 w-px bg-gray-900/10" aria-hidden="true" />
                  <a href="#" className="flex items-center gap-x-1">
                    <span className="absolute inset-0" aria-hidden="true" />
                    Online Medical Solutions
                    <ChevronRightIcon className="-mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                  </a>
                </div>
              </div>
              <h1 className="mt-10 max-w-lg text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                Prashanti Diagnostics Center
              </h1>
              <p className="mt-6 text-lg leading-8 text-gray-600">
              With our convenient blood test collection at home, you can skip the hassle of waiting rooms and get your tests done in the comfort of your own space.
              </p>
              <div className="mt-10 flex items-center gap-x-6">

                <CustomButton 
                  cardName='Medical Test'
                  color={primary}
                  link='Medical Test'
                  handleClick={handleClick}
                />
                <CustomButton 
                  cardName='Doctor Appointment'
                  color={secondary}
                  link='DoctorAppointment'
                  handleClick={handleClick}
                />
              </div>
            </div>
            <div className="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
              <svg viewBox="0 0 366 729" role="img" className="mx-auto w-[22.875rem] max-w-full drop-shadow-xl">
                <title>App screenshot</title>
                <defs>
                  <clipPath id="2ade4387-9c63-4fc4-b754-10e687a0d332">
                    <rect width={316} height={684} rx={36} />
                  </clipPath>
                </defs>
                <path
                  fill="#4B5563"
                  d="M363.315 64.213C363.315 22.99 341.312 1 300.092 1H66.751C25.53 1 3.528 22.99 3.528 64.213v44.68l-.857.143A2 2 0 0 0 1 111.009v24.611a2 2 0 0 0 1.671 1.973l.95.158a2.26 2.26 0 0 1-.093.236v26.173c.212.1.398.296.541.643l-1.398.233A2 2 0 0 0 1 167.009v47.611a2 2 0 0 0 1.671 1.973l1.368.228c-.139.319-.314.533-.511.653v16.637c.221.104.414.313.56.689l-1.417.236A2 2 0 0 0 1 237.009v47.611a2 2 0 0 0 1.671 1.973l1.347.225c-.135.294-.302.493-.49.607v377.681c0 41.213 22 63.208 63.223 63.208h95.074c.947-.504 2.717-.843 4.745-.843l.141.001h.194l.086-.001 33.704.005c1.849.043 3.442.37 4.323.838h95.074c41.222 0 63.223-21.999 63.223-63.212v-394.63c-.259-.275-.48-.796-.63-1.47l-.011-.133 1.655-.276A2 2 0 0 0 366 266.62v-77.611a2 2 0 0 0-1.671-1.973l-1.712-.285c.148-.839.396-1.491.698-1.811V64.213Z"
                />
                <path
                  fill="#343E4E"
                  d="M16 59c0-23.748 19.252-43 43-43h246c23.748 0 43 19.252 43 43v615c0 23.196-18.804 42-42 42H58c-23.196 0-42-18.804-42-42V59Z"
                />
                <foreignObject
                  width={316}
                  height={684}
                  transform="translate(24 24)"
                  clipPath="url(#2ade4387-9c63-4fc4-b754-10e687a0d332)"
                >
                  {/* <img src="https://tailwindui.com/img/component-images/mobile-app-screenshot.png" alt="" /> */}
                  <img src={require('../images/mobileScreen.png')} className='w-full'/>
                </foreignObject>
              </svg>
            </div>
          </div>
        </div>

        {/* Feature section */}
        <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-56 lg:px-8">
          <div className="mx-auto max-w-2xl lg:text-center">
            <h2 className="text-base font-semibold leading-7 text-indigo-600">Features</h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Revolutionizing Healthcare: Innovative Solutions for a Healthier Tomorrow
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-600">
            Leading Innovation for a Healthier Future.
            Empowering patients and professionals with advanced diagnostics and telehealth solutions.
            Join us in shaping tomorrow's healthcare landscape.
            </p>
          </div>
          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
              {secondaryFeatures.map((feature) => (
                <div key={feature.name} className="flex flex-col">
                  <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                    <feature.icon className="h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                    {feature.name}
                  </dt>
                  <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                    <p className="flex-auto">{feature.description}</p>
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>


      </main>

      {/* Footer */}
     <Footer/>
    </div>
  )
}

