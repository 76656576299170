import React,{useEffect,useState} from 'react'
import {doc, getDocs,collection, onSnapshot, updateDoc,query,where, orderBy } from 'firebase/firestore';
import {database} from '../../firebase/firebaseConfig'
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import axios from 'axios';




// const collRef = collection(database,'BloodTestBookings')





function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const CustomButton = ({approveBookings,id,name,doctor,email}) =>{

    const [isApproved,setIsApproved] = useState(false)

    const handleClick = () =>{
      setIsApproved(true)
      console.log(id)
      console.log('here at custom button')
      approveBookings(id,name,doctor,email)
    }
    return(
      <>
        {
            isApproved ?
            <span className='text-green-400'>Approved</span>
            :
            <button onClick={() =>handleClick()} className="text-indigo-600 hover:text-indigo-900">
              Approve
            </button>
          }
      </>
    )
  }                
 
  const CustomTable = ({data,approveBookings,id}) =>{
  

    return(
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">New Doctor Bookings</h1>
            <p className="mt-2 text-sm text-gray-700">
              Bookings made by customer for doctor appointment
            </p>
          </div>
          {/* <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
              type="button"
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Add user
            </button>
          </div> */}
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                      Doctor
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Patient
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Email
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Date
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {
                    data.length === 0 ?
                    <h1 className='p-5 w-full'>No Bookings to show</h1>
                    :
                    data.map((person) => (
                      <tr key={person.patientEmail} className="even:bg-gray-50">
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                          {person.doctor}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.patientName}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.patientEmail}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.created_at}</td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                          {
                            id === 'all'?
                            (
                              <CustomButton 
                            approveBookings={approveBookings}
                            id={person.id}
                            name={person.patientName}
                            doctor={person.doctor}
                            email={person.patientEmail}
                          />
                            )
                            :
                            (
                              ''
                            )
                          }
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }

const DocBooking = () => {

  const collRef = collection(database,'DoctorBookings')
  const [bookingData,setBookingData] = useState([])
  const [approvedData,setApprovedData] = useState([])
  

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect( () =>{
    let temp = []
    const unsubscribe = async() =>{
      const q = query(collRef,orderBy('created_at','desc'),where('approved','==',false))
       await getDocs(q)
      .then((snapshot) =>{
          snapshot.docs.forEach((doc) =>{
            const data = doc.data()
            const timestampInSeconds = data.created_at.seconds;
            const date = new Date(timestampInSeconds * 1000); // Convert seconds to milliseconds
            const formattedDate = date.toDateString();
            data.created_at = formattedDate
            temp.push({...data,id:doc.id})
          })
          setBookingData(temp)
          console.log(temp)
      })
    }
    unsubscribe();

  },[])

  // console.log(bookingData)

  const getApprovedData = async() =>{
    try {
      let temp=[]
      const q = query(collRef,orderBy('created_at','desc'),where('approved','==',true))
      await getDocs(q)
     .then((snapshot) =>{
         snapshot.docs.forEach((doc) =>{
          const data = doc.data()
          const timestampInSeconds = data.created_at.seconds;
          const date = new Date(timestampInSeconds * 1000); // Convert seconds to milliseconds
          const formattedDate = date.toDateString();
          data.created_at = formattedDate
          temp.push({...data})
         })
         setApprovedData(temp)
         console.log(temp)
     })
    } catch (error) {
      console.log(error)
    }
  }
  const approveBookings = async (id,name,doctor,email) =>{
      try {
        await axios.post('http://52.66.236.16:5001/api/notification/send-email',{name,doctor,email})
        .then(() =>{
          console.log('email sent')
          console.log(id)
          const ref = doc(database,'DoctorBookings',id)
          updateDoc(ref,{
            approved:true
          })
          .then(() =>{
            console.log('document updated')
          })
          .catch(err =>{
            console.log(err)
          })
        })
      } catch (error) {
        console.log(error)
      }
  }


  return (
    <div className='mt-32'>

      <h1 className='mt-10 text-3xl px-2'>Doctor Booking</h1>
       <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="New Bookings" {...a11yProps(0)} />
            <Tab onClick={getApprovedData} label="Approved" {...a11yProps(1)} />
            {/* <Tab label="Booking History" {...a11yProps(2)} /> */}
            </Tabs>
        </Box>

        <CustomTabPanel value={value} index={0}>
            <CustomTable
              id='all'
              data={bookingData}
              approveBookings={approveBookings}
            />
        </CustomTabPanel>

        <CustomTabPanel value={value} index={1}>
          <CustomTable
              id='approved'
              data={approvedData}
            />
        </CustomTabPanel>

        {/* <CustomTabPanel value={value} index={2}>
                <TableContainer  >
                        <Table aria-label="collapsible table">
                            <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell sx={{fontWeight:'bold'}}>Patient Name</TableCell>
                                <TableCell sx={{fontWeight:'bold'}} align="right">Address</TableCell>
                                <TableCell sx={{fontWeight:'bold'}} align="right">Application Date</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {allBooking.map((row) => (
                                <RowNew key={row.name} row={row} id='approved'/>
                                ))}
                            </TableBody>
                        </Table>
              </TableContainer>
        </CustomTabPanel> */}
        </Box>

    </div>
  )
}

export default DocBooking