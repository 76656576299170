import React from 'react'
import { BrowserRouter,Route,Routes } from 'react-router-dom'
import LandingPage from './pages/LandingPage'
import MedicalTestBooking from './pages/MedicalTestBooking'
import DoctorAppointment from './pages/DoctorAppointment'
import Protected from './core/Protected'
import LoginAdmin from './admin/LoginAdmin'

import { ContextProvider } from './context/contextProvider'
import BookingInfo from './pages/BookingInfo'
import Aboutus from './pages/Aboutus'
import Contactus from './pages/Contactus'
import Login from './pages/Login'
import Dashboard from './admin/Dashboard'
import Test from './pages/test'
import UserControl from './core/userAccessControl'
import Signup from './pages/Signup'
import Appointments from './pages/Appointments'
import { PrivacyPolicy } from './pages/PrivacyPoilcy'
import TermsCondition from './pages/TandC'
import Refund from './pages/Refund'
import ServiceDelivary from './pages/ServiceDelivery'


const App = () => {


  
  return (
    <ContextProvider>
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<LandingPage/>}/>
            <Route path='/Bookings' element={<BookingInfo/>}/>
            <Route path='/Appointments' element={<Appointments/>}/>
           
            <Route path='/login' element={<LoginAdmin/>}/>
            <Route path='/About Us' element={<Aboutus/>}/>
            <Route path='/ContactUs' element={<Contactus/>}/>
            <Route path='/Userlogin' element={<Login/>}/>
            <Route path='signup' element={<Signup/>}/>
            <Route path='privacy_policy' element={<PrivacyPolicy/>}/>
            <Route path='TermsAndCondition' element={<TermsCondition/>}/>
            <Route path='refund_cancellation' element={<Refund/>}/>
            <Route path='service_delivery' element={<ServiceDelivary/>}/>

            <Route element={<UserControl/>}>
              <Route path ='/Medical Test' element={<MedicalTestBooking/>} />
              <Route path='/DoctorAppointment' element={<DoctorAppointment/>}/>
            </Route>
            
            <Route path='test' element={<Test/>}/>
            <Route element={<Protected/>}>
                <Route path='/admin/*' element={<Dashboard/>}/>
            </Route>
            
        </Routes>
    </BrowserRouter>
    </ContextProvider> 
  )
}

export default App