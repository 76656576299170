import React, { useEffect, useState } from 'react';
import { collection, getDocs,orderBy,startAfter,limit, query } from 'firebase/firestore';
import { database } from '../../firebase/firebaseConfig';
import BillPdf from '../components/BillingComponents/BillPdf';



const CaseHistory = () => {
  
  const [data,setData] = useState([])
  const [sortDirection, setSortDirection] = useState('asc');
  const [lastVisible,setLastVisible] = useState(null)
  const [showBill,setShowBill] = useState(false)
  const [pdfItem,setPdfItem] = useState([])
  
  
  function loadMoreData() {
    let temp = data
    const collRef = collection(database,'StoreBilling')
    const q = query(collRef,orderBy('billNum','desc'),startAfter(lastVisible || 10000),limit(5))
    getDocs(q)
    .then(snapshot =>{
      snapshot.docs.forEach(doc =>{
        temp = [...temp,doc.data()]
      })
      setData(temp)
      const lastDoc = snapshot.docs[snapshot.docs.length - 1];
      // lastVisible = lastDoc && lastDoc.data().billNum;
      if(lastDoc) setLastVisible(lastDoc.data().billNum)
    })

  }

  useEffect(() =>{
    try{
        loadMoreData()
    }
    catch(err){
      console.log(err)
    }
  },[])

  const handleSort = (columnName) => {
    const sorted = [...data].sort((a, b) => {
      if (sortDirection === 'asc') {
        return a[columnName] < b[columnName] ? -1 : 1;
      } else {
        return a[columnName] > b[columnName] ? -1 : 1;
      }
    });

    setData(sorted);
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
  };

  const displayBill = (item) =>{
    setShowBill(true)
    setPdfItem(item)
  }
  const resetBill = () =>{
    setShowBill(false)
  }

  return (
    <div className='flex'>
      {
        showBill ? 
        <div className='fixed right-0 h-[85vh] w-1/3 overflow-y-scroll bg-gray-200 p-4'>
          <BillPdf billData={pdfItem} resetBill={resetBill}/>
        </div>
        :''
      }
      <div className={showBill ? 'w-1/2':'w-full'}>
        <div className='p-5'>
          <h1 className='text-2xl font-bold'>All Cases</h1>
          <section className='flex gap-2'>
          <input
            type='search'
            className='p-2 w-1/3 rounded-lg outline-none bg-white/50 border-2 border-gray-500'
            placeholder='search by date(yy/mm/dd)'
          />
          <button>Search</button>
          </section>
        </div>
        <table className='w-full'>
          <thead className='bg-blue-500/50'>
            <tr>
              <th className='p-5 text-lg text-gray-700' onClick={() => handleSort('billNum')}>ID</th>
              <th className='p-5 text-lg text-gray-700' >Patient Name</th>
              <th className='p-5 text-lg text-gray-700'>Phone Number</th>
              <th className='p-5 text-lg text-gray-700'>Date</th>
              <th className='p-5 text-lg text-gray-700'>Amount Paid</th>
              <th className='p-5 text-lg text-gray-700'>Actions</th>
            </tr>
          </thead>

          <tbody>
            {
              data.map(item =>(
                <tr key={item.billNum} className='border-b-2 border-slate-900'>
                  <td className='p-5 text-lg text-gray-700 text-center'>{item.billNum}</td>
                  <td className='p-5 text-lg text-gray-700 text-center'>{item.patientData.firstName} {item.patientData.lastName}</td>
                  <td className='p-5 text-lg text-gray-700 text-center'>{item.patientData.phoneNumber}</td>
                  <td className='p-5 text-lg text-gray-700 text-center'>{item.patientData.date}</td>
                  <td className='p-5 text-lg text-gray-700 text-center'>{item.estimate.total}</td>
                  <td>
                    <button onClick={() =>displayBill(item)} className='bg-red-400 p-2 rounded-lg text-white hover:bg-red-500/50'>View Bill</button>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
  
        <div className='w-full flex justify-center py-10'>
          <button
          type="button"
          onClick={loadMoreData}
          className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {lastVisible === 1 ? 'No more records':'Load More Data'}
          </button>
        </div>
        </div>
    </div>
  );
}

export default CaseHistory