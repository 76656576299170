import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

export default function ServiceDelivary(){
    return(
        <div>
            <Navbar/>
            <div className="mt-20">
            <div class= 'compalinace-page'>
            <div class= 'header-conatiner'>
                        <div class= 'merchant-logo'>
                <div class= 'logo-container'><div class= 'logo-text'>P</div></div>
            </div>
                                        <p class= 'header-text'>Prashanti Diagonistics</p>
        </div>
            <div class='compalinace-content'>
                                                                <div class='content-container'>
    <p class='content-head'>Shipping &amp; Delivery Policy</p>
    <div class='content-seprater'></div>
    <p class='updated-date'>Last updated on Dec 18th 2023</p>

    
            <p class='content-text'>Shipping is not applicable for business.</p>
    </div>
                     </div>
</div>
            </div>
            <Footer/>
        </div>
    )
}