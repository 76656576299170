
import NavBar from '../components/Navbar'
import React, { useState,useEffect } from "react";
import { addDoc,collection,doc,getDoc,getDocs} from "firebase/firestore";
import {database} from '../firebase/firebaseConfig'
import { getAuth } from 'firebase/auth';
import UserTestSelection from '../components/medicalTest/UserTestSelection';
import TestBilling from '../components/medicalTest/TestBilling';
import Footer from '../components/Footer';


const testTemplate = {
    lab:[],
    usg:[],
    ctscan:[],
    digitalXray:[],
    xray:[],
    outsource:[],
    ecg:[]
}

const collRef = collection(database,'BloodTestBookings')

const MedicalTestBooking = () => {
    
    // const [formData,setFormData] = useState(fields)
    const [userData,setUserData] = useState()
    const [test,setTest] = useState(null)
    const [userTest,setUserTest] = useState(testTemplate)
    const [estimate,setEstimate] = useState([])
    const [steps,setSteps] = useState(0)
    const auth = getAuth()

    const localstorage = window.localStorage
    const userid = localstorage.getItem('userId')

    useEffect(() =>{
      
      const sub = async() =>{
        try{
                const labRef = collection(database,'LabTest')
                const ref = doc(database,'users',userid)
                await getDoc(ref)
                .then((response) =>{
                    const data = response.data()
                    setUserData(data)
                })
                await getDocs(labRef)
                .then((snapshot) =>{
                    let temp = []
                    snapshot.docs.forEach(doc => {
                        temp[doc.id] = doc.data()
                    });
                    setTest(temp)
                    console.log(temp)
                })

            }
            catch(err){
              console.log(err)
            }
        }
      sub()

    },[])

    const handleTestDetails = (e) =>{
        const {name,value} = e.target
        setUserTest(prev =>({
            ...prev,
            [name]:value
        }))
    }

    const handleEstimate = (item,price) =>{

      const check = estimate.findIndex(test => test.name === item )
      if(check === -1 ){
        const pack = {
          name:item,
          price:price
        }
        setEstimate(prev =>([...prev,pack]))
      }
      else{
        const copyEstimate = estimate
        copyEstimate.splice(check,1)
        setEstimate(copyEstimate)
      }

      console.log(estimate)
    }

    const submitBooking = async () =>{
        try {
          const currentDate = new Date();
          const collRef = collection(database,'BloodTestBookings')
          // const {username,email,phoneNumber,age} = userData
          await addDoc(collRef,{approved:false,userId:userid,test:estimate,created_at:currentDate,...userData})
          .then(async (response) =>{
            // const userRef = doc(database,'users',userid)
            // await updateDoc(userRef,{
            //   medicalBookings:arrayUnion(response.id)
            // })
            console.log(`data added ${userid}`)
          })
        } catch (error) {
          console.log(error)
        }
    }

  

  return (
    <div className='mt-28'>
        <NavBar />
        <svg
            className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
              >
                <path d="M100 200V.5M.5 .5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
              <path
                d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect width="100%" height="100%" strokeWidth={0} fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" />
          </svg>

       {
        steps === 0 ?
        (
          <UserTestSelection 
            userData={userData} 
            userTest={userTest}
            handleTestDetails={handleTestDetails}
            handleEstimate={handleEstimate}
            setSteps={setSteps}
            test={test}
          />
        ):
        (
          <TestBilling
            userData={userData}
            estimate={estimate}
            submitBooking={submitBooking}
          />
        )
       }

       <Footer/>
   
    </div>
  )
}

export default MedicalTestBooking