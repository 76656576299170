import { Outlet,Navigate } from "react-router-dom";
import { useAuthContext } from "../context/authProvider";
const Protected = () => {

    const {authState} = useAuthContext()
    console.log(authState)
    const state = JSON.parse(localStorage.getItem('authStateLocal'))

    return(
      state ? <Outlet/> : <Navigate to='/login' replace/>
    )
  
};
export default Protected;