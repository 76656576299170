import React from 'react'
import {MdMenu} from 'react-icons/md'
import {AiOutlineInbox} from 'react-icons/ai'
import { useStateContext } from '../../context/contextProvider'
import { useNavigate } from 'react-router-dom'
import {FiMenu} from 'react-icons/fi'

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Typography } from '@mui/material'

const Navbar = () => {
    const {activeMenu,setActiveMenu} = useStateContext()
    const navigate = useNavigate()

    const logout = () =>{
      localStorage.removeItem('authStateLocal')
      navigate('/')
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
  
  return (
    <div className='flex justify-end items-center px-10 py-10 backdrop-blur-sm bg-white shadow-lg'>
         {/* <div onClick={() => setActiveMenu(prev => !prev)} className={`${activeMenu ? 'ml-80':'ml-0'}`}>
          <FiMenu/>
        </div>

        <div className='text-2xl font font-extrabold tracking-tighter text-slate-700'>
        <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Notification
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{width:700}}
      >
        <Typography sx={{width:300,padding:2}}>Notification</Typography>
        <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={handleClose}>My account</MenuItem>
        <MenuItem onClick={handleClose}>Logout</MenuItem>
      </Menu>
    </div> 
        </div> */}
        <button
        type="button"
        onClick={logout}
        className="rounded-full bg-indigo-600 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        logout
      </button>
    </div>
  )
}

export default Navbar