import React from 'react'
import Navbar from '../components/Navbar'
import {AiFillFacebook,AiOutlineMail} from 'react-icons/ai'
import {BsFillTelephoneFill} from 'react-icons/bs'
import {FaMapMarkedAlt} from 'react-icons/fa'
import Footer from '../components/Footer'

const Contactus = () => {
  return (
    <div>
        <div>
            <Navbar/>
        </div>

        <div className='flex flex-wrap mt-20 justify-center items-center text-slate-700 h-[80vh]'>
            <div className='p-2 md:w-1/2'>
                
                <h1>Merchant Legal entity name: Prashanti Diagonistics</h1>
                <h1>Registered Address: Prashanti Medical Hall and Diagnostic Center, NH 10 Rangpo Gangtok Road, Near Singtam Post Office Singtam SIKKIM 737134</h1>
                <h1>Operational Address: Prashanti Medical Hall and Diagnostic Center, NH 10 Rangpo Gangtok Road, Near Singtam Post Office Singtam SIKKIM 737134</h1>
                
            </div>
            <div className='flex flex-col gap-5'>
                <h1 className='text-3xl font-EB text-slate-900'>Contact us at:</h1>
                <div>
                    <h1 className='flex gap-1 items-center text-xl'><BsFillTelephoneFill/>Phone:</h1>
                    <h1>+91 824-0754624</h1>
                    <h1>+91 891-8901657</h1>
                    <h1>+91 8250-318089</h1>
                </div>

                <div>
                    <h1 className='flex gap-1 items-center text-xl'><AiOutlineMail/>Email</h1>
                    <h1>prashantidiag@gmail.com</h1>
                </div>

                <div>
                    <h1 className='text-xl'>Social Link:</h1>
                    <a href='https://www.facebook.com/PrashantiMedical?mibextid=LQQJ4d' className='text-xl hover:text-blue-700 active:scale-105'><AiFillFacebook/></a>
                </div>

                    
                <div>
                    <a 
                    className='font-EB text-xl text-slate-600 hover:text-blue-700 active:scale-105'
                    href="https://maps.google.com?q=Prashanti+Medical+medical+diagnostic+and+polyclinic,+near+by+Nepali+Dharmasala,+Singtam,+Sikkim+737134" target="_blank" rel="noopener noreferrer">
                    Open in Google Maps<FaMapMarkedAlt/>
                    </a>

                </div>
                
            </div>
        </div>
        <Footer/>
    </div>
  )
}

export default Contactus