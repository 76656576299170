// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC_nmqHE0lD_M0i6DbP0KDhq4BJ6EK0U28",
  authDomain: "prashantimedical-efc15.firebaseapp.com",
  projectId: "prashantimedical-efc15",
  storageBucket: "prashantimedical-efc15.appspot.com",
  messagingSenderId: "1058226685088",
  appId: "1:1058226685088:web:bf35a62fcabaeb537f75c5",
  measurementId: "G-R2LSQXW6DQ"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const database = getFirestore(app)
export const storage = getStorage(app)
const analytics = getAnalytics(app);