import React,{createContext,useContext,useState} from "react";

const StateContext = createContext()

export const AuthContextProvider = ({children}) =>{
    
    const [authState,setAuthState] = useState(false)

    return(
        <StateContext.Provider 
            value={{
                        authState,setAuthState
                   }}
        >
            {children}
        </StateContext.Provider>
    )
}

export const useAuthContext = () => useContext(StateContext)